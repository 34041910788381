export const products = [
  {
    name: "industrial oils",
    id: "industrial-oils",
    image: "../static/industrial-oil.jpg",
    tables: [
      {
        title: "MULTIPURPOSE OILS",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ULTRA MF",
              visc: "10, 22, 32, 46, 68, 100, 150, 220".split(", "),
            },
            td2: "10, 22, 32, 46, 68, 100, 150, 220".split(", "),
            td3: "Oils for the lubrication of bearings, hydraulics, slideways and gears. Classification: ISO-L-HM. Meet the requirements of the standards: DIN 51524-Part 2 HLP / DIN 51517-Part 3 CLP.",
          },
          {
            td1: {
              icon1: true,
              ref: "ULTRA ATOX",
              visc: "32, 46, 68, 100, 150, 220, 320, 460".split(", "),
            },
            td2: "32, 46, 68, 100, 150, 220, 320, 460".split(", "),
            td3: "White oils with additives for the lubrication of bearings, hydraulics, slideways, gears, circulation systems and compressors. Meet the requirements of the standards: ISO 6743/4-HM. DIN 51524-Part 2 HLP. DIN 51517-Part 3 CLP. DIN 51506-VD-L. Certified by NSF as H1 – suitable for accidental food contact.",
          },
          {
            td1: {
              icon1: true,
              ref: "ULTRASYNT ATOX",
              visc: "10, 22, 32, 46, 68, 100, 150, 220, 320, 460, 680".split(
                ", "
              ),
            },
            td2: "10, 22, 32, 46, 68, 100, 150, 220, 320, 460, 680".split(", "),
            td3: "Synthetic oils for long lasting high-performance lubrication for bearings, hydraulic systems, pumps, chains, gears and compressors. These oils exceed the requirements of standards ISO 6743/4 - HM. DIN 51524 - Part 2 HLP - DIN 51517 - Part 3 CLP - DIN 51506 -VD-L. Certified by NSF as H1 – suitable for accidental food contact.",
          },
          {
            td1: {
              icon1: true,
              ref: "ULTRA VBA",
              visc: "46, 68".split(", "),
            },
            td2: "46, 68".split(", "),
            td3: "Multifunctional lubricants with Vegetable-based bases, suitable for incidental food contact (NSF H1), totally free of hydrocarbons and biodegradable. Specials as hydraulics for vegetable oil mills. Quality level DIN 51524 Part 2 HLP / DIN 51517 Part 3 CLP.",
          },
          {
            td1: {
              icon1: true,
              ref: "ULTRATEF",
              visc: "H1",
            },
            td2: "100",
            td3: "Multifunctional oil with PTFE for lubrication of open mechanisms in the food industry. NSF approved as H1 for accidental food contact.",
          },
          {
            td1: {
              icon1: false,
              ref: "	ULTRA BIO",
              visc: "100",
            },
            td2: "100",
            td3: "Recommended for the lubrication of industrial gears, bearings, guides, chains, hydraulic circuits, pumps and circulation lubrication, in case of possible discharge of the lubricant into the environment.",
          },
        ],
      },

      {
        title: "GENERAL LUBRICANTS",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ENGINE OIL",
              visc: "7, 12".split(", "),
            },
            td2: "100, 150".split(", "),
            td3: "General lubricant for machinery and mechanisms bearing moderateloads at moderate speeds. Have high oiliness and thermal stability.",
          },
        ],
      },

      {
        title: "SPECIAL LUBRICANTS",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ULTRA SP2",
              visc: "",
            },
            td2: "2",
            td3: "Lubrication of high speed spindles operating at extremely close tolerances (“zero clearance”).",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRA CS 200 H1",
              visc: "",
            },
            td2: "200",
            td3: "It is especially formulated for the lubrication of high-speed canning machine mechanisms. Due to its high stability and minimal residue formation, it ensures correct lubrication under the most demanding operating conditions. Applicable in machinery that presents a risk of contamination of the product with the lubricant.",
          },
        ],
      },

      {
        title: "GEARS OILS",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "STANDARD VAREN EP",
              visc: "100, 150, 220, 320, 460, 680, 1000".split(", "),
            },
            td2: "100, 150, 220, 320, 460, 680, 1000".split(", "),
            td3: "Lubrication of industrial gearboxes. Meet the requirements of the standards: DIN 51 517 Part 3-CLP/ US STEEL 224 / AGMA 9005-D94.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAGEAR EP",
              visc: "150, 220, 320, 460, 680".split(", "),
            },
            td2: "150, 220, 320, 460, 680".split(", "),
            td3: "Oils with mineral base for gears operating in heavy duty load conditions. High protection against micropitting. They are approved by Siemens MD for use in Flender gears.",
          },
        ],
      },

      {
        title: "BIODEGRADABLE GEARS OILS",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ULTRAGEAR BIO",
              visc: "460",
            },
            td2: "460",
            td3: "Lubrication of industrial gears, bearings, slide ways, cams and other mechanisms requiring oil with EP properties, where there is a possibility of lubricant waste to the environment.",
          },
        ],
      },

      {
        title: "GEAR OILS FOR THE PRINTING INDUSTRY",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ULTRAPRESS AG",
              visc: "5 LA, 68 LA".split(", "),
            },
            td2: "68, 68".split(", "),
            td3: "Gear oils specially formulated to meet the requirements of printing machine manufacturers (MANROLAND, HEIDELBERG, etc).",
          },
        ],
      },

      {
        title: "SYNTHETIC GEAR OILS",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ULTRAGEAR PG",
              visc: "100, 150, 220, 320, 460".split(", "),
            },
            td2: "100, 150, 220, 320, 460".split(", "),
            td3: "Synthetic lubricants with high thermal stability and great lubricating power. For long life lubrication of all types of gears, circulation systems, bearings at high temperature (up to 200ºC).",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRASYNT EP",
              visc: "150, 220, 320, 460, 680, 1000".split(", "),
            },
            td2: "150, 220, 320, 460, 680, 1000".split(", "),
            td3: "Polyalphaolefin-based synthetic gear oils operating under severe load and temperature conditions. Approved by Siemens MD for use in Flender gears.",
          },
          {
            td1: {
              icon1: true,
              ref: "ULTRASYNT FGT",
              visc: "150",
            },
            td2: "150",
            td3: "Special synthetic oil for lubrication of bearings and gears under high loads in the food, beverage, pharmacy and cosmetic industries. Low coefficient of friction. Complies with standards: ISO 12925 - CKB, CKC, CKD / DIN 51517 Parts 1, 2 and 3 - C, CL and CLP. NSF certified as H1 - suitable for accidental food contact.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRASYNT WG",
              visc: "1000",
            },
            td2: "1000",
            td3: "Is particularly suitable for the long-term lubrication of worm gears. Can be used in circulation systems for lubrication of plain and roller bearings and other mechanical components, especially at high temperatures.",
          },
        ],
      },

      {
        title: "SLIDEWAYS AND DRIP-FREE",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "STANDARD SLIP",
              visc: "68, 100, 150, 220, 320, 1000".split(", "),
            },
            td2: "68, 100, 150, 220, 320, 1000".split(", "),
            td3: "Special oils for the lubrication of horizontal and vertical slideways and drip-free general lubrication.These oils contain unctuosity, adherence and extreme pressure (E.P.) additives. Quality level ISO 6743/4-L-HG / DIN 51524 - Part 2 - HLP / DIN 51517 - Part 3 - CLP.",
          },
        ],
      },

      {
        title: "CHAINS OILS",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ULTRACHAIN HT",
              visc: "220, 320".split(", "),
            },
            td2: "220, 320".split(", "),
            td3: "Synthetic lubricants for chains operating at temperatures up to 250ºC. High lubricating power. Residue-free.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRACHAIN PRO",
              visc: "150, 270, 460".split(", "),
            },
            td2: "150, 270, 460".split(", "),
            td3: "Oils with synthetic bases for chains with working temperatures of 200-220ºC. Great stability to oxidation and residue formation.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRA CLT",
              visc: "320",
            },
            td2: "320",
            td3: "Synthetic lubricant for chains operating from -30 up to 120ºC. Resistant to moisture, water and steam. High adherence. Chains, cables, cams, open mechanisms.",
          },
          {
            td1: {
              icon1: false,
              ref: "STANDARD SINT",
              visc: "15",
            },
            td2: "15",
            td3: "Biodegradable ester based synthetic chain oil. Operating temperature from -50 up to +180ºC. Suitable for operation in dusty conditions and where environment contamination is possible.",
          },
          {
            td1: {
              icon1: false,
              ref: "STANDARD CLT",
              visc: "460",
            },
            td2: "460",
            td3: "Mineral oil for chains and slide ways working up to +150ºC with high loads and moisture. Quality level ISO 6743/4 – category L-HG / DIN 51517 – CLP",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRACHAIN CPO",
              visc: "270",
            },
            td2: "270",
            td3: "Ester based synthetic oil for chain lubrication with operating temperature up to +250ºC. Compatible with some paints used in cataphoresis.",
          },
          {
            td1: {
              icon1: true,
              ref: "ULTRACHAIN FG",
              visc: "220",
            },
            td2: "220",
            td3: "Ester based synthetic oil for chain lubrication with operating temperature up to +250ºC. Certified by NSF as H1 – suitable for accidental food contact.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRADRY",
              visc: "320",
            },
            td2: "320",
            td3: "Dispersion of micronized graphite in a synthetic fluid for the lubrication of chains, bearings, sliding surfaces, support rings, etc. at temperatures up to 450ºC (600ºC with sufficient relubrication).",
          },
          {
            td1: {
              icon1: false,
              ref: "	ULTRACHAIN CTP 145",
              visc: "135",
            },
            td2: "135",
            td3: 'Special synthetic oil for lubricating chains in overhead and ground conveyors of cataphoretic paint lines. ULTRACHAIN CTP 145 is compatible with most water soluble paints and prevents the formation of craters, "fish eyes" and other surface defects. Working temperature up to 200ºC.',
          },
          {
            td1: {
              icon1: true,
              ref: "ULTRALUBE P-6",
              visc: "32",
            },
            td2: "32",
            td3: "Synthetic base oil for lubricating chains, guides, cams and other open mechanisms at low temperatures (down to -50ºC). Prevents the formation of crystals at low temperatures that can be projected on processed foods. Approved by NSF as H1 for accidental food contact.",
          },
        ],
      },

      {
        title: "COMPRESSORS AND PUMPS",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "STANDARD CP",
              visc: "46, 68, 100, 150".split(", "),
            },
            td2: "46, 68, 100, 150".split(", "),
            td3: "Mineral oils for air compressors with specific additives. High stability to oxidation, preventing the formation of deposits. Comply with the standard DIN 51 506. Category VD-L.",
          },
          {
            td1: {
              icon1: false,
              ref: "STICK PRESS AG",
              visc: "23",
            },
            td2: "320",
            td3: "Special oil for vacuum and pressure pumps in the Printing Industry. Complies with standard DIN 51 506 / VB-L / VC-L / VD-L.",
          },
          {
            td1: {
              icon1: false,
              ref: "STANDARD FRI",
              visc: "68",
            },
            td2: "68",
            td3: "Mineral naphthenic oil for the lubrication of refrigerating compressors of ammonia, freons R12 and R22, and propane-butane. Complies with DIN 51503 KAA/KC/KE.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRABAR HC",
              visc: "46",
            },
            td2: "46",
            td3: "Special hydrocarbon based oils for lubricating screw and vane compressors under severe operating conditions. Quality level: ISO 6743/3 - DAH / DIN 51 506 - VD-L.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRABAR HS",
              visc: "46, 68".split(", "),
            },
            td2: "46, 68".split(", "),
            td3: "PAO synthetic base oils for long-term lubrication of screw and vane compressors under severe operating conditions. Quality level: ISO 6743/3 - DAJ / DIN 51 506 - VD-L.",
          },
        ],
      },

      {
        title: "NEBULIZABLE OILS",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "NEBULOIL",
              visc: "2",
            },
            td2: "22",
            td3: "Highly refined oil with special additives for optimum pulverization. Recommended by FESTO.",
          },
        ],
      },

      {
        title: "SPECIAL HYDRAULICS FOR INDUSTRY",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "PRESOL HM",
              visc: "32, 46, 68, 100, 150".split(", "),
            },
            td2: "32, 46, 68, 100, 150".split(", "),
            td3: "High performance hydraulic oils with anti-wear, corrosion protection, anti-oxydation and anti-foam additives. Demulsifying. Meet DIN 51 524 Part 2 standard and equivalent.",
          },
          {
            td1: {
              icon1: false,
              ref: "PRESOL TH EP",
              visc: "3, 4, 5, 8".split(", "),
            },
            td2: "3, 4, 5, 8".split(", "),
            td3: "High performance hydraulic oils with anti-wear, corrosion protection, anti-oxydation and anti-foam additives. Demulsifying, ash-free, red colour. Meet DIN 51 524 Part 2 standard and equivalent.",
          },
          {
            td1: {
              icon1: false,
              ref: "PRESOL HV",
              visc: "15, 32, 46, 68, 100".split(", "),
            },
            td2: "15, 32, 46, 68, 100".split(", "),
            td3: "High performance hydraulic oils featuring high viscosity index, with anti-wear, corrosion protection, anti-oxydation and anti-foam additives. Demulsifying. Meet DIN 51 524 Part 3 standard and equivalent.",
          },
          {
            td1: {
              icon1: false,
              ref: "PRESOL HV PLUS",
              visc: "22, 46, 68".split(", "),
            },
            td2: "22, 46, 68".split(", "),
            td3: "Refined and microfiltered hydraulic oil, specially formulated for use in systems subject to large temperature variations, due to its very high viscosity index, and where special anti-wear and extreme pressure characteristics are required, great resistance to corrosion and stability to oxidation. Complies with specifications: ISO 6074 (HV) DIN 51524, Part 2 and 3 (HVI) MIL-L-24459.",
          },
          {
            td1: {
              icon1: false,
              ref: "PRESOL HFC",
              visc: "40",
            },
            td2: "46",
            td3: "Fire resistant water-glycol type hydraulic fluid, featuring red colour and high lubrication and stability.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAPRESS SC HM",
              visc: "46, 68".split(", "),
            },
            td2: "46, 68".split(", "),
            td3: "High performance hydraulic oils with anti-wear, corrosion protection, anti-oxydation and anti-foam additives. Demulsifying, ash-free. Meet DIN 51 524 Part 2 standard and equivalent.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAPRESS SC HV",
              visc: "46, 68".split(", "),
            },
            td2: "46, 68".split(", "),
            td3: "Highly refined and micro-filtered hydraulic oil, for use in systems exposed to wide temperature variation, and where requirements of anti-wear capacity, oxidation and corrosion protection are demanding. The product is ashless and free from heavy metals, it has low foam tendency and good deairation and demulsifying capacity.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAPRESS SCD",
              visc: "46",
            },
            td2: "46",
            td3: "High performance hydraulic oil with anti-wear, corrosion protection, anti-oxydation and anti-foam additives. Detergent, ash-free. Meets DIN 51 524 Part 2 standard and equivalent.",
          },
          {
            td1: {
              icon1: false,
              ref: "PRESOL HE FR",
              visc: "46, 68".split(", "),
            },
            td2: "46, 68".split(", "),
            td3: "Biodegradable fire resistant hydraulic fluid. is based on a high flash point biodegradable TMP ester and additives.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAPRESS HEES",
              visc: "46, 150".split(", "),
            },
            td2: "46, 150".split(", "),
            td3: "Recommended for hydraulic equipment operating in conditions where there may be a risk of incidental spillage or contamination of the environment, such as wind installations, hydroelectric plants, ports, ski resorts, etc. It is also suitable as a fire resistant hydraulic fluid. Working temperatures: from -30 ºC to 90 ºC (with point peaks of +120 ºC).",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAPRESS SC 46 SL",
              visc: "46",
            },
            td2: "46",
            td3: 'Refined and "ashless" microfiltered hydraulic oil for systems where oils with high degrees of cleanliness, anti-wear characteristics, oxidation stability and corrosion resistance are required.',
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAPRESS SC 46 KM",
              visc: "46",
            },
            td2: "46",
            td3: 'Refined and "ashless" microfiltered hydraulic oil for systems where oils with high degrees of cleanliness, anti-wear characteristics, good air separation behaviour, oxidation stability and corrosion resistance are required.',
          },
        ],
      },

      {
        title: "TECHNICAL OIL",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "SIVANO",
              visc: "1, 6".split(", "),
            },
            td2: "22, 1".split(", "),
            td3: "Technical oils for use as plasticizers and auxiliaries in chemical formulations.",
          },
          {
            td1: {
              icon1: false,
              ref: "WHITE SI",
              visc: "220",
            },
            td2: "220",
            td3: "Silicone oil with high thermal stability for the lubrication of mechanisms at temperatures from -30 to 200ºC, and as an auxiliary in spinning.",
          },
        ],
      },

      {
        title: "MEDICINAL OILS",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "FARMOL",
              visc: "3",
            },
            td2: "15, 32, 68".split(", "),
            td3: "Medicinal-grade white oils suitable as lubricants and auxiliary products in food processing, cosmetics and pharmaceuticals, etc. DAB 10 and FDA 178.3620 (a) quality level. Certified by NSF as 3H - suitable for direct contact with food.",
          },
          {
            td1: {
              icon1: false,
              ref: "FARMOL NL",
              visc: "22",
            },
            td2: "22",
            td3: "White medicinal oil with the same uses as Farmol 22 but not classified as dangerous by aspiration, thanks to its viscosity greater than 20.5 mm2/s.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAFARM",
              visc: "40",
            },
            td2: "40",
            td3: "Medicinal white oil stabilized with antioxidant, special for dough dividers in bread industry, as a release agent and auxiliary product. Quality level FDA 178.3620 (a), FDA 172.878. Certified by NSF as 3H - suitable for direct contact with food.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAFARM",
              visc: "VDP-E",
            },
            td2: "32",
            td3: "	Vegetable oil with additives, especially for divider-weighers in industrial bakeries. High resistance to heat and oxidation. Certified by NSF as 3H - suitable for direct contact with food.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRASIL CUT",
              visc: "300",
            },
            td2: "1",
            td3: "Food grade emulsion of silicone for lubrication and refrigeration in low severity metalworking operations.",
          },
          {
            td1: {
              icon1: false,
              ref: "WHITE OIL FILANTE",
              visc: " - ",
            },
            td2: " - ",
            td3: "Solid petroleum jelly of medicinal quality indicated for cosmetic and medicinal uses, and as a lubricant in the food industry. DAB 10 and FDA 178.3620 (a) quality level.",
          },
        ],
      },

      {
        title: "HEAT TRANSFER FLUIDS",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ACEITE TERMICO",
              visc: "3",
            },
            td2: "22",
            td3: "Synthetic thermal fluid for closed circuits. Operating temperature from -20 to + 315ºC.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRATERMIC P",
              visc: "6",
            },
            td2: "32",
            td3: "Synthetic based thermal fluid for closed circuits where a food grade product is required. Working temperature up to 315ºC. NSF H1 and HT1 certifications.",
          },
        ],
      },

      {
        title: "SPECIAL OILS",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ULTRA PFPE",
              visc: "150, 220, 460".split(", "),
            },
            td2: "150, 220, 460".split(", "),
            td3: "Synthetic oils with perfluoropolyether base for Lubrication in extreme conditions, presence of aggressive chemical products, high temperatures and lubrication for life.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRASYNT PG",
              visc: "68",
            },
            td2: "68",
            td3: "	Synthetic oil auxiliary lubricant in manufacturing processes of copper and aluminium wires.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRASYNT IF",
              visc: "40",
            },
            td2: "32",
            td3: "Special synthetic oil for lubricating sintered bearings. Very wide working temperature range: -60 to + 150ºC.",
          },
        ],
      },
    ],
  },

  {
    name: "lubricant greases",
    id: "lubricant-greases",
    image: "../static/lubricant-greases.jpg",
    tables: [
      {
        title: "CALCICES",
        tableHead: [
          "product",
          "NLGI consistency",
          "Drop point ºC",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "GREASE CA-T",
              visc: "2",
            },
            td2: "2",
            td3: "140",
            td4: "Greases for chassis and general lubrication up to 50ºC. Water-resistant and adhesive.",
          },
          {
            td1: {
              icon1: false,
              ref: "GREASE MAR",
              visc: "2 PLUS, 3 PLUS".split(", "),
            },
            td2: "2, 3".split(", "),
            td3: "140, 140".split(", "),
            td4: "Greases for chassis and general lubrication without load up to 90ºC. Good adhesion and water resistance.",
          },
        ],
      },

      {
        title: "LITHIUM",
        tableHead: [
          "product",
          "NLGI consistency",
          "Drop point ºC",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "LITKEN R",
              visc: "1, 2".split(", "),
            },
            td2: "1, 2".split(", "),
            td3: "195, 195".split(", "),
            td4: "Multipurpose lithium greases for the lubrication of bearings with medium loads, up to 120ºC.",
          },
          {
            td1: {
              icon1: false,
              ref: "LITKEN R 3 GREEN",
              visc: " ",
            },
            td2: "3",
            td3: "190",
            td4: "Multipurpose lithium grease for lubricating rolling bearings with light loads, up to 120ºC. Green color.",
          },
          {
            td1: {
              icon1: false,
              ref: "LITKEN COTTON GREASE",
              visc: " ",
            },
            td2: "0",
            td3: "-",
            td4: "Special lithium grease for lubrication of cotton picker spindle assemblies in machinery like Case, John Deere and International Harvester.",
          },
        ],
      },

      {
        title: "EP LITHIUM",
        tableHead: [
          "product",
          "NLGI consistency",
          "Drop point ºC",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "LITKEN A",
              visc: "2 EP, 3 EP".split(", "),
            },
            td2: "2, 3".split(", "),
            td3: "185, 185".split(", "),
            td4: "Lithium greases with extreme pressure (EP) additives for bearings under heavy loads and medium –low speed. Operating temperature up to 120ºC.",
          },
          {
            td1: {
              icon1: false,
              ref: "LITKEN EP",
              visc: "00, 0, 1, 2, 3".split(", "),
            },
            td2: "00, 0, 1, 2, 3".split(", "),
            td3: "-, 185, 185, 190, 190".split(", "),
            td4: "Lithium greases with extreme pressure (EP) additives for heavily loaded gearboxes and bearings. Operating temperature up to 120ºC.",
          },
          {
            td1: {
              icon1: false,
              ref: "LITKEN BLANC",
              visc: "2",
            },
            td2: "200",
            td3: "200",
            td4: "	High performance EP lithium grease. It allows extension of relubrication interval even in presence of high loads, water, dust, etc. Operating temperature from -20 up to +140ºC.",
          },
          {
            td1: {
              icon1: false,
              ref: "LITKEN HD",
              visc: "2",
            },
            td2: "200",
            td3: "200",
            td4: "High performance EP lithium grease. It allows extension of relubrication interval even in presence of high loads, water, dust, etc. Operating temperature from -20 up to +140ºC.",
          },
        ],
      },

      {
        title: "LITHIUM WITH SOLID LUBRICANTS",
        tableHead: [
          "product",
          "NLGI consistency",
          "Drop point ºC",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "LITKEMOL",
              visc: "71, 72".split(", "),
            },
            td2: "1, 2".split(", "),
            td3: "185, 185".split(", "),
            td4: "EP lithium greases with molybdenum disulphide, for extreme loads. Operating temperature up to 120ºC.",
          },
          {
            td1: {
              icon1: false,
              ref: "LITKEN MOLIBDENO",
              visc: "2, 3".split(", "),
            },
            td2: "2, 3".split(", "),
            td3: "180, 180".split(", "),
            td4: "Lithium grease with EP additives and molybdenum disulphide, for high loads and moderate speeds. Operating temperature up to 110ºC.",
          },
          {
            td1: {
              icon1: false,
              ref: "LITKEN MOLY",
              visc: "2",
            },
            td2: "2",
            td3: "185",
            td4: "Lithium grease with EP additives, molybdenum disulphide and other solid lubricants, for high loads and moderate speeds. Operating temperature up to 110ºC.",
          },
          {
            td1: {
              icon1: false,
              ref: "LITKEN GMV",
              visc: "2, 3".split(", "),
            },
            td2: "2, 3".split(", "),
            td3: "190",
            td4: "Lithium grease with EP additives and graphite for high loads and moderate speeds. Operating temperature up to 110ºC.Special for mobile equipment in mining, earth movement, etc.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRALIT SEG",
              visc: "34",
            },
            td2: "2",
            td3: "190",
            td4: "	Synthetic base lithium grease with solid lubricants for lubricating mechanical components operating at high speeds and shock loads. Lifetime lubrication of impact wrenches, drills, etc.",
          },
        ],
      },

      {
        title: "SPECIAL CALCIUM",
        tableHead: [
          "product",
          "NLGI consistency",
          "Drop point ºC",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "GELSA",
              visc: "00, 0, 200".split(", "),
            },
            td2: "00, 0, 2 ".split(", "),
            td3: "140, 140, 140".split(", "),
            td4: "Very adhesive greases with exceptional water resistance, for multipurpose lubrication up to temperatures of 120ºC.",
          },
          {
            td1: {
              icon1: false,
              ref: "GELSA BIO",
              visc: "0, 2".split(", "),
            },
            td2: "0, 2".split(", "),
            td3: "140, 140".split(", "),
            td4: "	Biodegradable, adherent and waterproof greases. Working temperature up to 120ºC.",
          },
          {
            td1: {
              icon1: false,
              ref: "GELSA XEP",
              visc: "310, 360".split(", "),
            },
            td2: "310, 360".split(", "),
            td3: "145, -".split(", "),
            td4: "Greases for chain saw machines used in extraction of marble and stone blocks. High load carrying capacity and water resistance.",
          },
          {
            td1: {
              icon1: false,
              ref: "GELSA BIO FLUID",
              visc: "-",
            },
            td2: "00, 000".split(", "),
            td3: "-",
            td4: "Biodegradable grease special for Wheel flange Lubrication systems in railways where solids lubricants are not allowed.",
          },
          {
            td1: {
              icon1: false,
              ref: "CALCOGRAF BIO FLUID",
              visc: "-",
            },
            td2: "00, 000".split(", "),
            td3: "-",
            td4: "Biodegradable grease with graphite, specially designed for spray lubrication of wheel flange in railways. Operating temperature from -20 up to +120ºC.",
          },
          {
            td1: {
              icon1: false,
              ref: "CALCOGRAF BIO HEAVY",
              visc: "-",
            },
            td2: "1",
            td3: "140",
            td4: "Biodegradable grease with graphite, with high adherence and load capacity. Rail lubricators, forestry equipment, etc. Operating temperature from -20 up to +120ºC.",
          },
          {
            td1: {
              icon1: false,
              ref: "GELSA LICA",
              visc: "2",
            },
            td2: "2",
            td3: "180",
            td4: "E.P. adherent lithium-calcium grease. Working temperature from -20 to +120ºC. Water resistant. Steel industry, paper mills, etc.",
          },
          {
            td1: {
              icon1: false,
              ref: "GELSA LT",
              visc: "215",
            },
            td2: "2",
            td3: "150",
            td4: "Grease with semi-synthetic base for lubrication of mechanisms at low temperature. Operating temperature from -40 up to +140ºC.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAGREASE BETA",
              visc: "2",
            },
            td2: "2",
            td3: "140",
            td4: "	Synthetic food grade grease for low temperature applications. Operating temperature from -60ºC up to 110ºC. NSF H1 certification.",
          },
        ],
      },

      {
        title: "SILICONE GREASES",
        tableHead: [
          "product",
          "NLGI consistency",
          "Drop point ºC",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ULTRASIL GREASE 2",
              visc: "",
            },
            td2: "2",
            td3: "Infusible",
            td4: "Silicone grease for a broad range of temperatures (-50 to 200ºC), with high resistance to oxidation and aggressive environments. For light-duty plastic-metal or plastic-plastic mechanisms.",
          },
        ],
      },

      {
        title: "SPECIAL INORGANIC GREASES",
        tableHead: [
          "product",
          "NLGI consistency",
          "Drop point ºC",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ULTRAGREASE ATOX",
              visc: "2",
            },
            td2: "2",
            td3: "infusible",
            td4: "Food grade high performance grease with synthetic base and PTFE. Working temperature up to 160ºC. Especially for can closers, washing plants, packaging and points exposed to the action of water and steam. NSF H1 certification.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAGREASE ALFA",
              visc: "2",
            },
            td2: "2",
            td3: "infusible",
            td4: "Synthetic grease with PTFE. Exceptional adherence and resistance to water and steam. Working temperature: 200ºC. Pellet presses, sterilizers, can closers, etc. NSF H1 certification.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRABEN",
              visc: "2",
            },
            td2: "2",
            td3: "infusible",
            td4: "	Bentonite grease for high temperatures. Working temperature up to +150ºC. High adherence and low oil separation.",
          },
        ],
      },

      {
        title: "ALUMINUM COMPLEX",
        tableHead: [
          "product",
          "NLGI consistency",
          "Drop point ºC",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ULTRAPLEX ALEA",
              visc: "0",
            },
            td2: "0",
            td3: "200",
            td4: "Aluminum complex greases for open gear lubrication by spraying, dipping or circulation. Excellent EP properties. For cement, mining, sugar mills, etc.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAPLEX ALEA 3000",
              visc: "FLUID, NLGI 00, MEDIUM".split(", "),
            },
            td2: "000, 00, 0".split(", "),
            td3: "-, -, 200".split(", "),
            td4: "Aluminum complex greases for open gear lubrication by spraying, dipping or circulation. Excellent EP properties. For cement, mining, sugar mills, etc.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAPLEX HAMMER",
              visc: "",
            },
            td2: "2",
            td3: "254",
            td4: "Aluminium complex grease for lubrication of hydraulic hammers. Maximum tool protection in extreme condition.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAPLEX ALMA",
              visc: "00, 1, 2".split(", "),
            },
            td2: "00, 1, 2".split(", "),
            td3: "-, 220, 250".split(", "),
            td4: "Aluminium complex greases with high adherence and load carrying capacity special for centralised lubrication systems in heavy industry. Operating temperatures up to 150ºC.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAPLEX ADH",
              visc: "1, 2".split(", "),
            },
            td2: "1, 2".split(", "),
            td3: "220, 250".split(", "),
            td4: "Aluminium complex greases with high adherence and sealing capacity special for centralised lubrication systems in heavy industry. Operating temperatures up to 150ºC.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAPLEX ALX",
              visc: "461, 2".split(", "),
            },
            td2: "1, 2".split(", "),
            td3: "240, 240".split(", "),
            td4: "Aluminium complex greases with molybdenum disulphide for heavy machinery in the extractive industries, steel, etc. Special for central lubrication equipment. High adhesion and water resistance.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAPLEX FG",
              visc: "00, 0, 1, 2".split(", "),
            },
            td2: "00, 0, 1, 2".split(", "),
            td3: "-, 210, 230, 250".split(", "),
            td4: "Food grade aluminium complex greases for lubrication of points with possible incidental food contact. High protection in front of wear, high loads and corrosion. Operating temperature from -20ºC up to 150ºC. NSF H1 certification.",
          },
        ],
      },

      {
        title: "LITHIUM COMPLEX",
        tableHead: [
          "product",
          "NLGI consistency",
          "Drop point ºC",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ULTRAPLEX LT",
              visc: "1, 2, 3".split(", "),
            },
            td2: "1, 2, 3".split(", "),
            td3: "270, 270, 280".split(", "),
            td4: "Lithium complex grease for bearings at high temperatures (150ºC), subjected to heavy loads and vibrations. Blue colour.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAGREASE GA",
              visc: "2",
            },
            td2: "2",
            td3: "270",
            td4: "Lithium complex grease for bearings at high temperatures (150ºC), subjected to heavy loads and vibrations, where the natural brown colour of the grease is preferred.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAPLEX LT",
              visc: "1 PTFE, 2 PTFE".split(", "),
            },
            td2: "1, 2".split(", "),
            td3: "270, 270".split(", "),
            td4: "Lithium complex grease for bearings at high temperatures (150ºC), subjected to heavy loads and vibrations. Blue colour. Low friction coefficient.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAPLEX LCH",
              visc: "12",
            },
            td2: "1, 2".split(", "),
            td3: "244",
            td4: "High-viscosity mineral-based grease and special complex lithium soap for heavily loaded mechanisms in the steel, cement, mining, etc. industries.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAPLEX AC",
              visc: "2",
            },
            td2: "2",
            td3: "270",
            td4: "High viscosity mineral oil based grease and lithium special complex soap for heavily loaded mechanisms in the steel, cement, mining, and other industries.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAPLEX MC",
              visc: "2",
            },
            td2: "2",
            td3: "270",
            td4: "Semi-synthetic lithium complex greases with molybdenum disulphide. For lubrication of slow mechanisms and high loads in heavy industry, mining, public works, etc. MC 2 with lower base oil viscosity for centralized equipment and low temperatures.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAPLEX ACK",
              visc: "2",
            },
            td2: "2",
            td3: "270",
            td4: "A semi-synthetic lithium complex grease with solid lubricants. For lubrication of slow mechanisms and high loads, especially bolts in mobile machinery in mining and public works.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAPLEX LTM",
              visc: "1500, 1".split(", "),
            },
            td2: "1",
            td3: "240",
            td4: "High viscosity oil based grease, special lithium soap and solid lubricants for the lubrication of crushers, mills and roller presses in the steel, cement, mining, etc. industries.",
          },
          {
            td1: {
              icon1: false,
              ref: "KRUPPER GREASE",
              visc: "-",
            },
            td2: "1, 2".split(", "),
            td3: "270",
            td4: "Grease for hydraulic hammers for drilling in special cartridge for automatic lubrication systems in hammers from ATLAS COPCO (KRUPP), HITACHI, KOMATSU…",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAPLEX XT 2 SYNT",
              visc: "-",
            },
            td2: "2",
            td3: "275",
            td4: "Lithium complex grease with synthetic base. PTFE and solid lubricants for mechanisms operating at temperatures up to 180ºC, very high loads and medium speeds.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAPLEX TG",
              visc: "0-1 SYNT, 1 SYNT".split(", "),
            },
            td2: "0-1, 1".split(", "),
            td3: "250, 250".split(", "),
            td4: "Lithium complex greases with synthetic base for mechanisms operating at temperatures up to 200°C, low speeds, high loads and vibrations.",
          },
        ],
      },

      {
        title: "CALCIUM SULFONATE GREASES",
        tableHead: [
          "product",
          "NLGI consistency",
          "Drop point ºC",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "	ULTRAPLEX CS",
              visc: "1, 12, 2".split(", "),
            },
            td2: "1, 1-2, 2".split(", "),
            td3: ">300, >300, >300".split(", "),
            td4: "They are specially indicated for lubrication of bearings, open gears, gearboxes, gear couplings, wire ropes and other mechanical components under heavy loads working in adverse environmental conditions. ULTRAPLEX CS 1 and CS 12 are specially suitable for central lubrication systems.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAPLEX MCS",
              visc: "1, 2".split(", "),
            },
            td2: "1, 2".split(", "),
            td3: "300, 300".split(", "),
            td4: "They are specially indicated for lubrication of bearings, open gears, gearboxes, gear couplings, wire ropes and other mechanical components under heavy loads working in adverse environmental conditions.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAPLEX CSFG",
              visc: "460, 462".split(", "),
            },
            td2: "0, 2".split(", "),
            td3: "300, 300".split(", "),
            td4: "Calcium sulphonate greases with a high viscosity semi-synthetic base oil for lubricating mechanisms exposed to high loads and temperatures, water and humidity. NSF H1 certification for incidental food contact.",
          },
        ],
      },

      {
        title: "SPECIAL GREASES",
        tableHead: [
          "product",
          "NLGI consistency",
          "Drop point ºC",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ULTRAPLEX BA",
              visc: "221, 2223, 4612, 462".split(", "),
            },
            td2: "1, 2-3, 1-2, 2".split(", "),
            td3: "245, 245, 247, 247".split(", "),
            td4: "Greases made of mineral base oil thickened with bariumcomplex soap and additives for improving resistance to oxidation, corrosion and wear. It is specially indicated for lubrication of bearings and other mechanical components working under medium speeds, heavy loads and adverse environmental conditions.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAPLEX BA",
              visc: "SYNT 24",
            },
            td2: "2",
            td3: "250",
            td4: "	Greases made of mineral base oil thickened with barium complex soap and additives for improving resistance to oxidation, corrosion and wear. It is specially indicated for lubrication of bearings and other mechanical components working under medium speeds, heavy loads and adverse environmental conditions.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAGREASE TOP",
              visc: "1, 2".split(", "),
            },
            td2: "1, 2".split(", "),
            td3: "-",
            td4: "Grease with fluorinated base and PTFE thickener special for lubrication in extreme conditions. Operating temperature from -20 to up to +260ºC. Resistant to solvents and almost all chemical products. Food grade NSF H1 approved. High load carrying capacity.",
          },
        ],
      },

      {
        title: "PASTE",
        tableHead: [
          "product",
          "NLGI consistency",
          "Drop point ºC",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ULTRAPASTE CU",
              visc: "12, 25".split(", "),
            },
            td2: "1, 2".split(", "),
            td3: "190, 190".split(", "),
            td4: "Pastes with a high content of copper and solid lubricants for the lubrication of drilling equipment and as assembly pastes for threaded joints.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAPASTE CU",
              visc: "12 F",
            },
            td2: "0",
            td3: "190",
            td4: "For cable lubrication at temperatures up to 1100°C in the steel industry.",
          },
        ],
      },
    ],
  },

  {
    name: "water miscible coolants",
    id: "water-miscible-coolants",
    image: "../static/water-miscible-coolants.jpg",
    tables: [
      {
        title: "FORMALDEHYDE FREE COOLANTS",
        tableHead: [
          "product",
          "Type emulsion",
          "Water hardness range [ºfH]",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "CUTTINSOL GREEN 102",
            },
            td2: "Green, Transparent".split(", "),
            td3: "0, 80".split(", "),
            td4: 'Synthetic soluble based on nitrites for grinding ("green coolant").',
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRASOL 50 R",
            },
            td2: "Transparent",
            td3: "0, 40".split(", "),
            td4: "Synthetic product for grinding processes and light and medium machining of hard-metals and steels.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRASOL HM-S",
            },
            td2: "Transparent",
            td3: "0, 40".split(", "),
            td4: "Synthetic product for grinding processes and light and medium machining of hard-metals and steels.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRASOL 075 NS",
            },
            td2: "Transparent",
            td3: "0, 90".split(", "),
            td4: "Synthetic soluble without mineral oil, free of boron for grinding and machining of ferrous metals, cast iron, nodular cast iron and medium and low difficulty carbon steels. Can be used for conventional aluminum machining.",
          },
          {
            td1: {
              icon1: false,
              ref: "CUTTINSOL 5",
            },
            td2: "Milky",
            td3: "0, 40".split(", "),
            td4: "Mineral based. Multi-metal. Light and general machining. Free from boron, chlorine, nitrites and amines.",
          },
          {
            td1: {
              icon1: false,
              ref: "CUTTINSOL 231",
            },
            td2: "Opalescent",
            td3: "0, 30".split(", "),
            td4: "Semi-synthetic coolant for machining and grinding of steel and in some cases aluminum.",
          },
          {
            td1: {
              icon1: false,
              ref: "CUTTINSOL 210",
            },
            td2: "Opalescent",
            td3: "0, 30".split(", "),
            td4: "Semi-synthetic coolant for machining and grinding of steel and in some cases aluminum.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRASOL 335",
            },
            td2: "Milky, Opalescent".split(", "),
            td3: "0, 40".split(", "),
            td4: "Soluble fluid for machining and grinding of steels, stainless steel, cast iron and aluminum. It is used in operations of medium and high difficulty.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRASOL 631",
            },
            td2: "Milky",
            td3: "0, 30".split(", "),
            td4: "	Soluble fluid for machining all aluminum alloys, steels and compatible with yellow metals. It is used in operations of medium and high difficulty.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRASOL E 55",
            },
            td2: "Opalescent",
            td3: "5, 50".split(", "),
            td4: "Boron-free soluble for machining and grinding of steels, castings and aluminum. It can be used in some cases with yellow metals and is not suitable for galvanizing. Lubro-refrigerant based on state-of-the-art components, EP additives, and anticorrosives so it can be used in medium and high difficulty operations.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRASOL 671",
            },
            td2: "Milky",
            td3: "15, 80".split(", "),
            td4: "Boron-free soluble fluid and amines for machining yellow metals.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRASOL 440",
            },
            td2: "Milky, Opalescent".split(", "),
            td3: "16, 60".split(", "),
            td4: "	Semi-synthetic soluble fluid with a high mineral oil content. To be used in metalworking applications on steel and conventional aluminium, on medium and high severity operations. For soft and medium water hardness.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRASOL 452",
            },
            td2: "Milky, Opalescent".split(", "),
            td3: "20, 70".split(", "),
            td4: "	Soluble fluid for machining all aluminum alloys, steels and compatible with yellow metals. It is used in operations of medium and high difficulty.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRASOL BIO 475",
            },
            td2: "Milky, Opalescent".split(", "),
            td3: "10, 70".split(", "),
            td4: "Boron and amines free fluid for multimetal machining. Suitable for operations of medium and high difficulty. Compatible with aerospace aluminiums",
          },
        ],
      },

      {
        title: "GENERAL RANGE COOLANTS",
        tableHead: [
          "product",
          "Type emulsion",
          "Water hardness range [ºfH]",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ULTRASOL 064",
            },
            td2: "Transparent",
            td3: "0, 80".split(", "),
            td4: "Boron-free synthetic fluid for flat and cylindrical grinding of ferrous metals, cast iron, nodular cast iron and high detergent carbon steels.",
          },
          {
            td1: {
              icon1: false,
              ref: "CUTTINSOL 24 RO",
            },
            td2: "Opalescent",
            td3: "0, 30".split(", "),
            td4: "Semi-synthetic, bacteriostatic, anti-corrosive. Multi-metal. Grinding and machining of medium severity. Free from chlorine, nitrites and secondary amines.",
          },
          {
            td1: {
              icon1: false,
              ref: "CUTTINSOL 5 T",
            },
            td2: "Milky",
            td3: "0, 50".split(", "),
            td4: 'Soluble fluid for general machining ("white coolant") and shaped for all types of metals.',
          },
          {
            td1: {
              icon1: false,
              ref: "CUTTINSOL 7",
            },
            td2: "Transparent, Opalescent".split(", "),
            td3: "0, 100".split(", "),
            td4: "Semi-synthetic product for machining ferrous metals, cast iron, nodular cast iron and carbon steels. It withstands extreme water conditions and high pressures.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRASOL 60 EP",
            },
            td2: "Milky, Opalescent".split(", "),
            td3: "5, 60".split(", "),
            td4: "Soluble fluid for machining and grinding of steels, stainless steel, cast iron and aluminum. It is used in operations of medium and high difficulty.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRASOL 25 NF",
            },
            td2: "Milky, Opalescent".split(", "),
            td3: "10, 50".split(", "),
            td4: "Boron and amines-free soluble fluid for machining steel, cast iron, aluminum and yellow metals. It can be used in some cases for machining magnesium. Operations of medium and high difficulty. Compatible with aerospace aluminums.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRASOL 436",
            },
            td2: "Milky, Opalescent".split(", "),
            td3: "0, 30".split(", "),
            td4: "Semi-synthetic soluble fluid to be used on medium and high severity metalworking operations on steel and aluminium alloys.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRASOL 45 AD",
            },
            td2: "Milky, Opalescent".split(", "),
            td3: "020, 80".split(", "),
            td4: "Semi-synthtetic, bacteriostatic, anti-corrosive. Recommended for machining of cast iron,as well as grinding of alloy steels. Excellent washing power. Free from chlorine, nitrites and secondary amines.",
          },
        ],
      },

      {
        title: "COMPATIBLE SLIDEWAY OILS",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ULTRAGUIDE 68",
            },
            td2: "68",
            td3: 'Oil recomended for horizontal slideway featuring a high adhesion and preventing the "stick-slip" effect. Compatible with coolants due to its separation power.',
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAGUIDE 220",
            },
            td2: "220",
            td3: 'Oil recomended for vertical slideway featuring a high adhesion and preventing the "stick-slip" effect. Compatible with coolants due to its separation power.',
          },
        ],
      },

      {
        title: "ADDITIVES FOR BATHS",
        tableHead: ["product", "Description and applications"],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "BIOPOL K 14",
            },
            td2: "Bactericide and fungicide for tank side treatments. 1 liter for 10.000 liters of emulsion.",
          },
          {
            td1: {
              icon1: false,
              ref: "DEFOAMER E",
            },
            td2: "Antifoam for tank side treatments. Approx 1 liters for 10.000 liters of emulsion.",
          },
          {
            td1: {
              icon1: false,
              ref: "	DEFOAMER K",
            },
            td2: "Additive to increase the water hardness based on calcium salts.",
          },
        ],
      },

      {
        title: "AUXILIARY PRODUCTS",
        tableHead: ["product", "Description and applications"],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "STANDARD SYSTEM CLEANER",
            },
            td2: "System cleaner for chaging of used emulsions. Add 1-2 % on the used emusion.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRADEG WASH",
            },
            td2: "Water based cleaner for exterior and interior wall machines. Ready to use. Does not attack paintings.",
          },
        ],
      },
    ],
  },

  {
    name: "neat cutting oils",
    id: "neat-cutting-oils",
    image: "../static/neat-cutting-oils.jpg",
    tables: [
      {
        title: "NEAT CUTTING OILS (CHLORINE FREE)",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ULTRACUT GR 3.5",
            },
            td2: "3,5",
            td3: "High perfomance neat cutting oil for honing and grinding. Also suitable for general machining of non-ferrous metals.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRACUT GR 5",
            },
            td2: "5",
            td3: "	High perfomance neat cutting oil for honing and grinding. Also suitable for general machining of non-ferrous metals. Based on HC base oi",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRACUT GR 3.5",
            },
            td2: "3,5",
            td3: "High perfomance neat cutting oil for honing and grinding. Also suitable for general machining of non-ferrous metals.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRACUT GR 7",
            },
            td2: "7",
            td3: "	High perfomance neat cutting oil for honing and grinding. Also suitable for general machining of non-ferrous metals. Based on HC base oil.",
          },
          {
            td1: {
              icon1: false,
              ref: "	ULTRACUT GR 10 S",
            },
            td2: "10",
            td3: "High perfomance neat cutting oil for finishing operations. Also suitable for general machining of non-ferrous metals. Based on HC base oils.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRACUT GR 12 CS",
            },
            td2: "13",
            td3: "High perfomance neat cutting oil for finishing operations. Also suitable for general machining of non-ferrous metals. Based on HC base oils.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRACUT GR BIO 8",
            },
            td2: "8",
            td3: "Low viscosity neat cutting oil, based on advanced biodegradable ester technologies. It’s designed for grinding and severe super finishing operations.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRACUT ECO",
            },
            td2: "21",
            td3: "General machining of copper, tin and bronze. Bar cutting, turning, milling and drilling of low severity of low-alloy steels. High washing power. Anti-mist additives content. Chlorine-free.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRACUT UNI",
            },
            td2: "21",
            td3: "General machining of non-ferrous metals and ferrous metals (easy operations). Suitable for aluminum grinding. Bar turning.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRACUT MV 2122",
            },
            td2: "21",
            td3: "Multimetal neat oil for metalworking operations of medium severity. Based on base oil group III. Specific for bar turning and applications with fogging problems.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRACUT EXTRA",
            },
            td2: "21",
            td3: "High severety machining of steel and stainless steel. Machining of foged stainles steel. Anti-mist addives content.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRACUT EXTRA NGM",
            },
            td2: "22",
            td3: "High severety machining of steel. Based on HC base oils. Anti-mist addives content. Precision bar turned parts. Medical implant machining.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRACUT SUPER",
            },
            td2: "30",
            td3: "Recommended for gear cutting and threading by rolling. Medium and severe maching of ferrous metals.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRACUT MV 2114",
            },
            td2: "15",
            td3: "Suitable for high severity machining of all kinds of metals. Can be used for drilling and broaching at low speed. Recommended for PFIFFNER machines (high precision bar turning).",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRACUT TP 2740",
            },
            td2: "15",
            td3: "High severety machining of steel. Recommended for difficult deep drilling and broaching. Based on HC base oils. Anti-mist addives content.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRACUT BIO 22",
            },
            td2: "22",
            td3: "Straight vegetable-based oil and derivated from renowable raw materials. Universal for most operations and materials. It's ideal for medical industry applications as well.",
          },
        ],
      },

      {
        title: "EDM DIELECTRIC OILS",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ULTRACUTTING	LIGHT",
            },
            td2: "1,6",
            td3: "Very low viscosity oil special for electrodischarge machining. Suitable for machines with self-washing filters. Tested in ONA.",
          },
          {
            td1: {
              icon1: false,
              ref: "CUTTINOIL  EL",
            },
            td2: "2,5",
            td3: "Very low viscosity oil special for electrodischarge machining, particulary for roughing. High flash point.",
          },
        ],
      },

      {
        title: "MQL CUTTING OILS",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ULTRACUT BIO 5",
            },
            td2: "5",
            td3: "Recommended as a lubricating and cooling agent in general machining, grinding and MQL operations of steel, aluminium and yellow metals (copper, brass, bronze), and grinding of high alloy and stainless steels.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRACUT BIO 300 SP",
            },
            td2: "32",
            td3: "Syntetic oil specially recommended for general machining.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRACUT BIO 500 SP",
            },
            td2: "46",
            td3: "Syntetic oil specially recommended for general machining.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRACUT BIO 600 SP",
            },
            td2: "32",
            td3: "Syntetic oil specially recommended for general machining and difficult operations. Special for drilling.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRACUT BIO ALC 25",
            },
            td2: "27",
            td3: "	Aluminum cutting process by disc-saw and by belt-cut. Large and complicated parts.",
          },
        ],
      },

      {
        title: "MQL CUTTING OILS",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ULTRALUB EV 460 S",
            },
            td2: "2,5",
            td3: "Aluminum cutting process by disc-saw. Aluminum profiles.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRALUB EV 9",
            },
            td2: "2,4",
            td3: "Aluminum cutting process by disc-saw. Aluminum profiles. Vanishing oil for forming operations.",
          },
          {
            td1: {
              icon1: false,
              ref: "ALUMOL FLUID 32",
            },
            td2: "31",
            td3: "Aluminum cutting process by disc-saw and aluminum machining.",
          },
        ],
      },
    ],
  },

  {
    name: "metal forming lubricants",
    id: "metal-forming-lubricants",
    image: "../static/metal-forming-lubricants.jpg",
    tables: [
      {
        title: "NEAT OILS FOR STAMPING AND DEEP DRAWING (CHLORINATED)",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "EMBATOL A",
            },
            td2: "22",
            td3: "Chlorinated very low viscosity oil for stamping and drawing of ferrous metals.",
          },
          {
            td1: {
              icon1: false,
              ref: "EMBATOL 10",
            },
            td2: "80",
            td3: "	Chlorinated low viscosity synthetic oil for stamping, drawing and fine blanking. Multimeta",
          },
          {
            td1: {
              icon1: false,
              ref: "EMBATOL 15",
            },
            td2: "150",
            td3: "Chlorinated oil for deep drawing of ferrous metals.",
          },
          {
            td1: {
              icon1: false,
              ref: "EMBATOL 20",
            },
            td2: "220",
            td3: "	For deep drawing, allowing high deformation rate in one single stage, with excellent surface finishing. Contains chlorine. Free from heavy metals.",
          },
          {
            td1: {
              icon1: false,
              ref: "EMBATOL 40",
            },
            td2: "460",
            td3: "	For deep drawing of stainless steels, allowing high deformation rate in one single stage, with excellent surface finishing. Maximum EP content. Does not contain mineral oil. Contains chlorine. Free from heavy metals.",
          },
          {
            td1: {
              icon1: false,
              ref: "EMBATOL 95",
            },
            td2: "1100",
            td3: "Chlorinated synthetic oil for drawing bars, rods, of stainless steel and very complicated processes. Multimetal.",
          },
        ],
      },

      {
        title: "NEAT OILS FOR STAMPING AND DEEP DRAWING (CHLORINATED)",
        tableHead: [
          "product",
          "NLGI consistency",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ULTRALUB AC 5",
            },
            td2: "5",
            td3: "Semi-vainising oil for stamping, die cutting. Multimetal. Washable.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRALUB AC",
            },
            td2: "25, 70, 90, 100, 150, 220".split(", "),
            td3: "Neat olis for stamping and drawing.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRALUB SP",
            },
            td2: "100, 220, 320, 680, 1050".split(", "),
            td3: "Neat olis for stamping, deep drawing, fine blanking, wire drawing.",
          },
        ],
      },

      {
        title: "LUBRICANTS FOR FINE BLANKING",
        tableHead: [
          "product",
          "NLGI consistency",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "EMBATOL 10",
            },
            td2: "80",
            td3: "Chlorinated low viscosity synthetic oil for stamping, drawing and fine blanking. Multimetal.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRALUB AC 70",
            },
            td2: "70",
            td3: "Neat oli for stamping and drawing.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRALUB SP 100",
            },
            td2: "100",
            td3: "	Neat Oil for complicated operations: Stamping, deep drawing, fine blanking… Ferrous metals.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRALUB SP 220",
            },
            td2: "220",
            td3: "	Neat Oil for complicated operations: Stamping, deep drawing, fine blanking,… Ferrous metals.",
          },
        ],
      },

      {
        title: "ANTI-PROJECTIONS",
        tableHead: ["product", "Description and applications"],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "DESTAC BIO PROJECT",
            },
            td2: "Used to prevent the adhesion of projections on metal surfaces during welding and eliminates the need for chiseling, grinding or wire brushing.",
          },
        ],
      },

      {
        title: "LUBRICANTS FOR COLD FORMING (FASTENERS)",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)	",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "	ULTRALUB CF 500 EP	",
            },
            td2: "110",
            td3: "	110 Chlorine free oil for cold stamping of medium to high severity.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRALUB CF 800 MF",
            },
            td2: "75",
            td3: "	Chlorine free oil for cold stamping of medium to high severity. Multifuntinal suitable for machine lubrication.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRALUB CF 907",
            },
            td2: "140",
            td3: "Chlorine free oil for high severity cold stamping.",
          },
        ],
      },

      {
        title: "SOLUBLE OILS FOR TUBE FORMING WITH WELDING",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)	",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ULTRADRAW 110 T",
            },
            td2: "80",
            td3: "Mineral based emulsifiable fluid, with EP performance, specially recommended for stamping and deep drawing of aluminum and alloys, but suitable for all metals. Free from chlorine, boron, amines and nitrites.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRADRAW 150 EP",
            },
            td2: "80",
            td3: "Mineral based emulsifiable fluid, with high EP performance, recommended for deep drawing of steel and steel alloys. Free from chlorine, boron, amines and nitrites.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRADRAW E 68",
            },
            td2: "120",
            td3: "Synthetic solube oil for stamping contining EP and AW additives. Suitable for deep drawing of aluminium alloys.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRADRAW PASTE TK",
            },
            td2: "Paste",
            td3: "	Water-soluble for deep drawing of stainless steel, steel and aluminium. Can be applied pure or diluted.",
          },
        ],
      },

      {
        title: "SOLUBLE OILS FOR TUBE FORMING WITH WELDING",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)	",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ULTRADRAW 805",
            },
            td2: "80",
            td3: "Soluble olis with high content in mineral oil for tube forming with welding.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRADRAW TDR",
            },
            td2: "80",
            td3: "Semi-synthetic emulsifiable fluid for medium severity stamping of ferrous metals and cold roll forming of steel and galvanized steel profiles.",
          },
        ],
      },

      {
        title: "SOLUBLE OILS FOR TUBE FORMING WITH WELDING",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)	",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ECODRAW A-40",
            },
            td2: "40",
            td3: "Neat oil for stamping and deep drawing of non-ferrous metals.",
          },
          {
            td1: {
              icon1: false,
              ref: "ECODRAW A-80",
            },
            td2: "95",
            td3: "Neat oil for stamping and deep drawing of non-ferrous metals. Special for very difficult operations with aluminium alloys.",
          },
          {
            td1: {
              icon1: false,
              ref: "ECODRAW A-120",
            },
            td2: "120",
            td3: "Neat oil for stamping and deep drawing of non-ferrous metals. Special for very difficult operations with aluminium alloys.",
          },
          {
            td1: {
              icon1: false,
              ref: "ECODRAW A-70",
            },
            td2: "135",
            td3: "Neat oil for stamping and deep drawing of ferrous and non-ferrous metals. Specific for high difficulty operations with aluminium alloys.",
          },
        ],
      },

      {
        title: "SOLUBLE OILS FOR TUBE FORMING WITH WELDING",
        tableHead: [
          "product",
          "Flash point (ºC)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: true,
              ref: "ULTRALUB LIGHT",
            },
            td2: "75",
            td3: "Fast vanishing synthetic lubricant for short cutting cycles. Odourless, does not leave any build-up.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRALUB EV 3",
            },
            td2: "75",
            td3: "Fast vanishing synthetic lubricant for short cutting cycles. Ideal for insulating and low-emission glass. Odourless.",
          },
          {
            td1: {
              icon1: true,
              ref: "ULTRALUB EV 3 ATOX",
            },
            td2: "60",
            td3: "High drying speed, specially recommended for stamping of steel cans, with some degree of deep drawing. Faint odour. In many cases it does not require degreasing prior to subsequent surface processing. H1 registered (acccidental food contact).",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRALUB EV 5",
            },
            td2: "45",
            td3: "	Very high drying speed, for stamping, forming and die cutting of metal sheet, allowing medium drawing. Light smell. In many cases it does not require degreasing prior to subsequent surface processing. Flammable.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRALUB EV 6 ATOX",
            },
            td2: "",
            td3: "Vanishing oil for light stamping of non ferrous metals.",
          },
          {
            td1: {
              icon1: true,
              ref: "ULTRALUB EV 7 ATOX",
            },
            td2: "105",
            td3: "High drying speed, for stamping in fast presses and deep drawing of food cans. Faint odour. In many cases it does not require degreasing prior to subsequent surface processing. H1 registered (accidental food contact).",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRALUB EV 10 EP",
            },
            td2: "75",
            td3: "High drying speed, with EP performance for stamping, medium drawing and punching of steels and alloy steels (automotive parts, shelving, water fittings, etc.). Leaves a residual film featuring excellent corrosion protection, which in many cases does not require degreasing prior to subsequent surface processing.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRALUB EV 15 EP",
            },
            td2: "85",
            td3: "Vanishing oil used for forming of ferrous metals. It can be used in high difficulty operations, even up to 3 mm sheet thickness.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRALUB EV 307 AL",
            },
            td2: "65",
            td3: "Vanishing oil for stamping of no ferrous metals. Very suitable for the production of aluminium radiators fins, compatible with refrigeration gases.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRALUB EV 350 AL",
            },
            td2: "75",
            td3: "Vanishing oil for stamping of no ferrous metals. Very suitable for the production of aluminium radiators fins, compatible with refrigeration gases.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRALUB EV 542",
            },
            td2: "65",
            td3: "Vanishing oil used for forming of all metal types. Specific for magnetic sheet. Very compatible with elastomers.",
          },
          {
            td1: {
              icon1: true,
              ref: "ULTRALUB EV 2645 S",
            },
            td2: "105",
            td3: "Semi- vanishing oil for stamping of non ferrous metals. Medium-hight difficulty. H1 certificate.",
          },
        ],
      },

      {
        title: "FLOAT GLASS CUTTING",
        tableHead: [
          "product",
          "Flash point (ºC)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ULTRALUB LIGHT",
            },
            td2: "72",
            td3: "Vanishing oil for cutting of flat glass. Food grade H1 level.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRALUB EV 3",
            },
            td2: "72",
            td3: "Vanishing oil for cutting of flat glass.",
          },
          {
            td1: {
              icon1: false,
              ref: "CUTTINOIL SPECIAL GLASS",
            },
            td2: "66",
            td3: "Vanishing oil for moderate glass cutting cycles. Does not leave any residue.",
          },
          {
            td1: {
              icon1: false,
              ref: "GLASSCUT 65 HS",
            },
            td2: "75",
            td3: "Vanishing oil, odourless and clear, chlorine free, with a well balanced composition of synthetic additives. User friendly",
          },
        ],
      },

      {
        title: "NEAT OILS FOR ALUMINIUM WIRE DRAWING",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ECODRAW A-90",
            },
            td2: "95",
            td3: "Neat oil for aluminium wire drawing. All diameters.",
          },
          {
            td1: {
              icon1: true,
              ref: "ECODRAW A-100 ATOX",
            },
            td2: "100",
            td3: "Neat oil for aluminium wire drawing. All diameters. H1 certificated.",
          },
          {
            td1: {
              icon1: false,
              ref: "ECODRAW A-150",
            },
            td2: "150",
            td3: "Neat oil for aluminium wire drawing. Rod wire.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRACUT GR 10 S",
            },
            td2: "10",
            td3: "Neat oil for aluminium wire drawing. Fine diameters.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRALUB 105 TT",
            },
            td2: "2,8",
            td3: "Synthetic neat oil for very fine aluminium wire drawing. Wire cleaning fluid.",
          },
        ],
      },

      {
        title: "LUBRICANTS FOR ALUMINIUM EXTRUSION",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ULTRACAST NB D20",
            },
            td2: "120",
            td3: "	Boron nitrade dispersion in water for hot forming. Non-stick, release liner and lubricant.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRACUT BIO ALC 25",
            },
            td2: "27",
            td3: "	Oil specifically designed for cutting aluminium by saw (disc or saw blade). It does not stain the aluminium on thermal degreasing and it is odourless.",
          },
          {
            td1: {
              icon1: false,
              ref: "	ALUMOL FLUID 32",
            },
            td2: "31",
            td3: "Cutting of aluminum profiles by disc-saw. Applied by microspraying devices.",
          },
          {
            td1: {
              icon1: false,
              ref: "ALUMOL EV 460S",
            },
            td2: "1,6",
            td3: "Cutting of aluminum profiles by disc-saw. Applied by microspraying devices.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRALUB EV 9",
            },
            td2: "2,4",
            td3: "Cutting of aluminum profiles by disc-saw. Applied by microspraying devices.",
          },
        ],
      },

      {
        title: "ALUMINIUM DIE CASTING AT HIGHT PRESSURE",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ULTRACAST W 2100",
            },
            td2: "<10",
            td3: "Release agent for aluminium and zamak die casting.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRACAST INJECT Z",
            },
            td2: "<10",
            td3: "Release agent for zamak and aluminium die casting.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRACAST 2070 MZK",
            },
            td2: "2,3",
            td3: "Solvent based mold releaser to be used in zamak.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRACAST PST 460 M",
            },
            td2: "450",
            td3: "Mineral based lubricant to be used in the piston.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRACAST PST 460 V",
            },
            td2: "450",
            td3: "Lubricant for the piston based on synthetics oil.",
          },
        ],
      },

      {
        title: "COLD DRAWING OF BARS",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ULTRALUB AC 127",
            },
            td2: "125",
            td3: "Oil for cold drawing of rolled bars and bright bars",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRALUB AC 161",
            },
            td2: "160",
            td3: "Oil for cold drawing of rolled bars and bright bars.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRALUB AC 280",
            },
            td2: "280",
            td3: "Oil for cold drawing of rolled bars and bright bars. High performance.",
          },
          {
            td1: {
              icon1: false,
              ref: "PREVENT-RUST GR 10",
            },
            td2: "10",
            td3: "Oil for calibrated bar with lubricating and anticorrosive capacity.",
          },
        ],
      },

      {
        title: "HOT FORGING AND STEEL FOUNDRIES IN THE STEEL INDUSTRY",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ULTRACAST W 950",
            },
            td2: "800",
            td3: "Synthetic fluid based on salts for hot forging. Soluble in water.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAFORGE GW 13",
            },
            td2: "400",
            td3: "Colloidal graphite in water. Release agent for forging and stamping.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAFORGE EM",
            },
            td2: "<10",
            td3: "Fluid free of solids components for hot forging for aluminium work peices.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRAFORGE B",
            },
            td2: "900",
            td3: "Dispersion of colloidal graphite in mineral oil for hot forging / stamping of brass.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRACAST GR 3500",
            },
            td2: "2000",
            td3: "Agent de démoulage pour la coulée semi-continue dáluminium. Production de billets d'aluminium.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRACAST 209",
            },
            td2: "7",
            td3: "Release agent for green moulding in iron cast production.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRACAST 85",
            },
            td2: "85",
            td3: "Release agent for steel continius casting. Production of billets, slabs.",
          },
        ],
      },

      {
        title: "QUENCHING OILS",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "TEMPLEX N",
            },
            td2: "25",
            td3: "Non-washable quenching oil. Low viscosity, high stability against oxidation and high flash point.",
          },
          {
            td1: {
              icon1: false,
              ref: "TEMPLEX VS",
            },
            td2: "22",
            td3: "Washable quenching oil for high speed cooling. Low viscosity, high stability against oxidation and high flash point.",
          },
          {
            td1: {
              icon1: false,
              ref: "TEMPLEX SOL 441",
            },
            td2: "-",
            td3: "Water soluble fluid, based on synthetic polymers and used for steel quenching. Working temperature between 20 ºC and 50 ºC. At high concentrations the cooling rate is similar to an oil, while at low concentrations it is higher than water.",
          },
        ],
      },
    ],
  },

  {
    name: "metal protection",
    id: "metal-protection",
    image: "../static/metal-protection.jpg",
    tables: [
      {
        title: "CORROSION PREVENTION PRODUCTS",
        tableHead: ["product", "Type of film", "Description and applications"],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "PREVENT RUST A-1",
            },
            td2: "Oily",
            td3: "Water displacing corrosion proctection fluid. Ideal for parts machined with water miscible coolants. Leaves a thin protecting film.",
          },
          {
            td1: {
              icon1: false,
              ref: "PREVENT RUST A-3",
            },
            td2: "Oily",
            td3: "Water displacing and anti-corrosion fluid, non VOC. For mid and long term protection.",
          },
          {
            td1: {
              icon1: false,
              ref: "PREVENT RUST A-5",
            },
            td2: "Oily",
            td3: "Corrosion protection fluid leaving a thick film for medium and long term protection.Compatible with halogenated solvents used in industrial washing machines.",
          },
          {
            td1: {
              icon1: false,
              ref: "PREVENT RUST A-7 AD",
            },
            td2: "Oily",
            td3: "Anti-corrosion product, with an oily film, having a medium thickness. For long term protection.",
          },
          {
            td1: {
              icon1: false,
              ref: "PREVENT RUST A-25",
            },
            td2: "Oily",
            td3: "Water displacing corrosion protection fluid leaving a film of medium thickness, featuring a high content in anti-oxidation and corrosion protection additives.",
          },
          {
            td1: {
              icon1: false,
              ref: "PRODASPIT",
            },
            td2: "Oily",
            td3: "Water displacing corrosion protection fluid. Ideal for parts machined with water-miscible coolants. Leaves a thin but very resistant film (> 80 h of salt spray chamber test).",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRARRUST A-45",
            },
            td2: "Oily",
            td3: "Corrosion protection fluid, special for lubricating roll forming processes of metal sheet.",
          },
          {
            td1: {
              icon1: false,
              ref: "PREVENT RUST A-80 HF",
            },
            td2: "Oily",
            td3: "Dewatering fluid, based on aliphatic solvents (aromatic free) and with a high content of corrosion inhibitors. With a high water displacement capacity, It leaves a very thin and transparent oily / waxy film to provide long term protection to machined parts when stored.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRARRUST 120W",
            },
            td2: "Waxy",
            td3: "Water-displacing anticorrosive fluid leaving a dry film. Used to protect ferrous metal parts for long periods indoors or under cover, and in land transport.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRARRUST 800W",
            },
            td2: "Waxy",
            td3: "Water-displacing anticorrosive fluid leaving a dry film with a waxy finish. Used to protect ferrous metal parts for long periods indoors or under cover, and in land transport.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRARRUST 800W SPRAY",
            },
            td2: "Waxy",
            td3: "Spray version of this waxy film metal protector for long intervals.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRARRUST TN",
            },
            td2: "Oily",
            td3: "Water soluble anticorrosive fluid, based on mineral oil and anticorrosive additives, that cleans and protects the parts machined with water soluble fluids. Specially designed for ferrous metals (cast iron and steel), although it can be used for non ferrous metals.",
          },
          {
            td1: {
              icon1: false,
              ref: "PREVENT RUST S WAX",
            },
            td2: "Waxy",
            td3: "Water based fluid, not containing solvants, ready to use or to be diluted. For mid and long term protections",
          },
        ],
      },
    ],
  },

  {
    name: "degreasers",
    id: "degreasers",
    image: "../static/degreasers.jpg",
    tables: [
      {
        title: "DEGREASERS",
        tableHead: ["product", "Flash point	", "Description and applications"],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "STANDARD CLEAN M-4",
            },
            td2: "> 60ºC",
            td3: "Mineral based cleaner for engines and machinery. Emulsifiable in water for easy removal.",
          },
          {
            td1: {
              icon1: false,
              ref: "STANDARD CLEAN 50",
            },
            td2: "-",
            td3: "Slightly alkaline water-soluble cleaner ideal for high pressure and steam cleansing. Concentration up to 50%.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRADEG WS 80",
            },
            td2: "-",
            td3: "Neutral water-soluble cleaner for application by spray, dipping and ultrasounds, leaving a protecting film. Concentration 2-5%.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRADEG FG PLUS",
            },
            td2: "-",
            td3: "Strong alkaline cleaner for industrial environment and polluting agents. Concentration 0.5-3% in water, or neat.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRADEG 20",
            },
            td2: "> 28ºC",
            td3: "Solvent-based cleaner featuring effective detergency of oils, greases and other carbonated residues. Very fast evaporation. Does not leave residues, chlorine-free.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRADEG 40",
            },
            td2: "> 40ºC",
            td3: "Degreaser based on highly refined paraffinic compounds. The product does not contain aromatic substances, chlorine nor HCFC. It is especially designed to feature a very rapid evaporation without leaving any type of residues.",
          },
          {
            td1: {
              icon1: true,
              ref: "ULTRADEG 65",
            },
            td2: "> 61ºC",
            td3: "Solvent-based cleaner featuring effective detergency of oils, greases and other carbonated residues. Fast evaporation, not flammable. Does not leave residues, chlorine-free.",
          },
          {
            td1: {
              icon1: false,
              ref: "STANDARD CLEAN FC-5",
            },
            td2: "> 100ºC",
            td3: "Solvent-based cleaner featuring effective detergency of oils, greases and other carbonated residues. Slow evaporation, not flammable. Does not leave residues, chlorine- and VOC-free.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRADEG BIO",
            },
            td2: "65 ºC",
            td3: "Based on biodegradable esters and renewable sources. Used for cleaning ferrous and non-ferrous metals to remove grease, oils and dirt in general. It is suitable for releasing natural and synthetic resins.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRADEG WASH",
            },
            td2: "Does not apply",
            td3: "Water based cleaner for exterior and interior wall machines. Ready to use. Does not attack paintings.",
          },
        ],
      },

      {
        title: "BLANKETS AND INK ROLLERS IN PRINTING INDUSTRY",
        tableHead: ["product", "Description and applications"],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "CLEANGRAFIC 10",
            },
            td2: "Specially formulated fluid for manual cleaning blankets, rubber and ink rollers used in the printing industry. Easily washable with water.",
          },
        ],
      },

      {
        title: "MISCELLANEOUS",
        tableHead: ["product", "Description and applications"],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "DESBLOQUEANTE",
            },
            td2: "Multipurpose oil for releasing rusted mechanisms, temporary antirust protection and lubrication of small mechanisms.",
          },
        ],
      },
    ],
  },

  {
    name: "sprays",
    id: "sprays",
    image: "../static/sprays.jpg",
    tables: [
      {
        title: "SPRAYS",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              icon2: false,
              ref: "MOLISPRAY",
            },
            td2: "600",
            td3: "High-performance lubricant with molybdenum disulphide, with high adhesion for lubricating heavy-loaded mechanisms attemperatures of up to 450ºC.",
          },
          {
            td1: {
              icon1: false,
              icon2: false,
              ref: "ULTRA MOLY DRY SPRAY",
            },
            td2: "-",
            td3: "Dry film of molybdenum disulphide for lubrication in very dusty environment, tight mechanisms, threaded spindles, slides, racks and chains.",
          },
          {
            td1: {
              icon1: false,
              icon2: false,
              ref: "MULTIUSO SPRAY",
            },
            td2: "5",
            td3: "Multipurpose penetration oil with excellent rust dissolving properties, high penetration and lubricating capacity.",
          },
          {
            td1: {
              icon1: true,
              icon2: true,
              ref: "SILICON SPRAY",
            },
            td2: "220",
            td3: "Silicone aerosol specially formulated to use as a mould stripper for plastic material, antiadhesive for welding and as a polisher. NSF H1 Certification.",
          },
          {
            td1: {
              icon1: false,
              icon2: false,
              ref: "	SOLDADURA SPRAY",
            },
            td2: "1000",
            td3: "Anti-adhesive for welding in places where subsequent surface treatment must be applied.",
          },
          {
            td1: {
              icon1: false,
              icon2: false,
              ref: "MULTIGREASE SPRAY",
            },
            td2: "Grease NLGI-2",
            td3: "Aerosol formulated to deposit a thin lubricating layer of very adhesive, water-resistant grease. Multipurpose lubrication up to +110ºC.",
          },
          {
            td1: {
              icon1: false,
              icon2: false,
              ref: "MULTILUBE PTFE SPRAY",
            },
            td2: "100",
            td3: "Multipurpose lubricating aerosol with PTFE which provides a very low friction coefficient. Excellent penetration and anti-oxidation capacity.",
          },
          {
            td1: {
              icon1: false,
              icon2: false,
              ref: "MULTISLIP SPRAY",
            },
            td2: "46",
            td3: "Mould stripper without silicone for plastics and rubber. Anti-adhesive for welding. Allows for subsequent surface treatments: serigraphy, varnishing, painting, etc.",
          },
          {
            td1: {
              icon1: true,
              icon2: false,
              ref: "ULTRAGREASE TF SPRAY",
            },
            td2: "Grease NLGI-2",
            td3: "Food grade tefloned grease in spray. High resistance to water, steam and high temperatures (peaks up to 200ºC). NSF H1 certification for incidental food contact.",
          },
          {
            td1: {
              icon1: true,
              icon2: false,
              ref: "ULTRATEF H1 SPRAY",
            },
            td2: "100",
            td3: "Food grade tefloned lubricant with good adherence and penetration properties. For chains, cables, guides, etc. at peak temperatures up to 150ºC. NSF H1 certification for incidental food contact.",
          },
          {
            td1: {
              icon1: true,
              icon2: false,
              ref: "ULTRA MULTI FOOD SPRAY",
            },
            td2: "15",
            td3: "Multipurpose penetration oil with excellent rust dissolving properties, high penetration and lubricating capacity. NSF H1 certification for incidental food contact.",
          },
          {
            td1: {
              icon1: false,
              icon2: false,
              ref: "ULTRACUT UNI SPRAY",
            },
            td2: "20",
            td3: "Multi-metal and multipurpose machining oil. Contains anti-mist additives. For bar turning.",
          },
          {
            td1: {
              icon1: false,
              icon2: false,
              ref: "ULTRAPLEX ALEA 3000 MEDIUM SPRAY",
            },
            td2: "Grease NLGI-0",
            td3: "Aluminium complex grease with high viscosity base oil for lubricating open gears and cables operating under high loads and unfavourable environmental conditions.",
          },
          {
            td1: {
              icon1: true,
              icon2: false,
              ref: "ULTRA CWF 1500 SPRAY",
            },
            td2: "1500",
            td3: "A synthetic spray lubricant with PTFE for lubricating open points in hard working conditions. NSF approved as suitable for incidental contact with food (H1). Chains, cables, guides, cams and other mechanisms in the food and beverage industry.",
          },
          {
            td1: {
              icon1: false,
              icon2: false,
              ref: "ULTRARRUST 800 W SPRAY",
            },
            td2: "-",
            td3: "Water-displacing anticorrosive fluid leaving a dry film with a waxy finish. Used to protect ferrous metal parts for long periods indoors or under cover, and in land transport. In spray form.",
          },
          {
            td1: {
              icon1: true,
              icon2: true,
              ref: "ULTRADEG 65 SPRAY",
            },
            td2: "1,0",
            td3: "Solvent-based cleaner featuring effective detergency of oils, greases and other carbonated residues. Fast evaporation, not flammable. Does not leave residues, chlorine-free.",
          },
        ],
      },
    ],
  },

  {
    name: "mould releasers for construction and civil works",
    id: "mould-releasers",
    image: "../static/mould-releasers.jpg",
    tables: [
      {
        title: "CONCRETE AND ASPHALT RELEASING AGENTS",
        tableHead: ["product", "Water soluble", "Description and applications"],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "DESTAC A",
            },
            td2: "YES",
            td3: "Water-miscible concrete releasing agent. Does not stain nor affect the subsequent painting or plastering. For all types of moulds.",
          },
          {
            td1: {
              icon1: false,
              ref: "DESTAC 30 L",
            },
            td2: "NO",
            td3: "Low viscosity concrete releasing agent for direct use.",
          },
          {
            td1: {
              icon1: false,
              ref: "DESTAC BIO",
            },
            td2: "NO",
            td3: "Biodegradable low viscosity concrete releasing agent for direct use. Special for brickwork walls.",
          },
          {
            td1: {
              icon1: false,
              ref: "DESTAC 8 MZ",
            },
            td2: "NO",
            td3: "Synthetic-based concrete releasing agent where the inside and outside appearance is especially critical. Recommended for ceramic tiles.",
          },
          {
            td1: {
              icon1: false,
              ref: "DESTAC MR",
            },
            td2: "NO",
            td3: "Specially developed for ceramic and refractory bricks. It produces no fumes and leaves no carbonaceous residue. It can also be used in other applications as prefabricated concrete. A high quality in the released piece is reached, without spots or cracks on it.",
          },
          {
            td1: {
              icon1: false,
              ref: "DESTAC A 30 BIO	",
            },
            td2: "NO",
            td3: "New technology release agent ready to use. It is an emulsion of vegetable components, colourless, odourless and non-flammable. It is a non-toxic product that poses no risk to users or the environment.",
          },
          {
            td1: {
              icon1: false,
              ref: "DESTAC VEG",
            },
            td2: "NO",
            td3: "Biodegradable fluid based on vegetable oil. Non-adhering fluid specifically developed to replace petroleum source solvents. Used as a non-adhering coating for the transport and application of road asphalt.",
          },
        ],
      },
    ],
  },

  {
    name: "products for textile industry",
    id: "textile-industry",
    image: "../static/textile-industry.jpg",
    tables: [
      {
        title: "KNITTING MACHINERY LUBRICANTS",
        tableHead: [
          "product",
          "ISO VG Viscosity (CST at 40° C)",
          "Chemical composition",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ULTRATEX CR",
              visc: "22, 32, 46".split(", "),
            },
            td2: "22, 32, 46".split(", "),
            td3: "Synthetic oils, additives and emulsifiers",
            td4: "Circular and rectilinear knitting machines.",
          },
          {
            td1: {
              icon1: false,
              ref: "ROS OIL A",
              visc: "A-22 PLUS, A-32 PLUS, A-46 PLUS".split(", "),
            },
            td2: "22, 32, 46".split(", "),
            td3: "Synthetic oils and additives high refined mineral oils, emulsifiers and additive",
            td4: "Circular and rectilinear knitting machines.",
          },
          {
            td1: {
              icon1: false,
              ref: "ROS OIL A-22 PLUS TW",
              visc: "",
            },
            td2: "22",
            td3: "Yellowish mineral oil, additives and surfactant",
            td4: "Circular and rectilinear knitting machines. Washable.",
          },
          {
            td1: {
              icon1: false,
              ref: "ROS OIL W",
              visc: "W-2, W-3, W-4".split(", "),
            },
            td2: "22, 32, 46".split(", "),
            td3: "White oils and additives",
            td4: "Circular and rectilinear knitting machines.",
          },
          {
            td1: {
              icon1: false,
              ref: "ROS OIL",
              visc: "A-22, FE".split(", "),
            },
            td2: "22",
            td3: "Mineral oil, additives and surfactant",
            td4: "Circular knitting machines. Rapid washing processes.",
          },
          {
            td1: {
              icon1: false,
              ref: "ROS CLEAN",
              visc: "",
            },
            td2: "6",
            td3: "Mineral oil and additives",
            td4: "Cleaning oil for needles.",
          },
        ],
      },

      {
        title: "SPINNING LUBRICANTS - MINERAL OIL",
        tableHead: [
          "product",
          "Chemical composition",
          "Character",
          "Active M.",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ISOCARD A-30",
              visc: "",
            },
            td2: "Mineral oils, acids, fatty, additive",
            td3: "Anionic",
            td4: "100%",
            td5: "Woollen spinning. Wool and blends.",
          },
          {
            td1: {
              icon1: false,
              ref: "ISOTEX",
              visc: "",
            },
            td2: "Mineral oil with emulsifiers",
            td3: "Anionic",
            td4: "100%",
            td5: "Warps and wefts",
          },
          {
            td1: {
              icon1: false,
              ref: "ISOTEX RM",
              visc: "",
            },
            td2: "Mineral oil, emulsifiers and additives",
            td3: "Anionic",
            td4: "100%",
            td5: "High-density threads, very low friction coefficient.",
          },
        ],
      },

      {
        title: "SPINNING LUBRICANTS - WHITE MINERAL OIL",
        tableHead: [
          "product",
          "Chemical composition",
          "Character",
          "Active M.",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ISOTEX AB",
              visc: "",
            },
            td2: "White oil plus emulsifiers",
            td3: "Non-ionic",
            td4: "100%",
            td5: "Warps and wefts. Polyethylene.",
          },
          {
            td1: {
              icon1: false,
              ref: "ISOTEX DT",
              visc: "",
            },
            td2: "White oil and additives",
            td3: "Non-ionic",
            td4: "100%",
            td5: "Warps, double torsion, wefts.",
          },
          {
            td1: {
              icon1: false,
              ref: "ISOTEX 112",
              visc: "",
            },
            td2: "White oil, additives antiprojection",
            td3: "Non-ionic",
            td4: "100%",
            td5: "Texturised and coning oils.",
          },
          {
            td1: {
              icon1: false,
              ref: "ISOTEX T NEW",
              visc: "",
            },
            td2: "Medicinal oil and ester",
            td3: "Non-ionic",
            td4: "100%",
            td5: "Polypropilene. Special for production citrius bag. Meets UE 20/2011",
          },
          {
            td1: {
              icon1: false,
              ref: "ISOMED GGR",
              visc: "",
            },
            td2: "Medicinal oil, plus additives",
            td3: "Anionic",
            td4: "100%",
            td5: "Polyethylene. Fibres in contact with food stuff.",
          },
        ],
      },

      {
        title: "SPINNING LUBRICANTS - SYNTHETIC LUBRICANTS",
        tableHead: [
          "product",
          "Chemical composition",
          "Character",
          "Active M.",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ISOIL OP 40",
              visc: "",
            },
            td2: "Copolymer plus anti-static agents",
            td3: "Cationic",
            td4: "25%",
            td5: "Open-end spinning and grindin.",
          },
          {
            td1: {
              icon1: false,
              ref: "ISOIL B-P",
              visc: "",
            },
            td2: "Copolymer, lubricant and anti-static",
            td3: "Anionic",
            td4: "80%",
            td5: "PA, PE and PP fibres.",
          },
          {
            td1: {
              icon1: false,
              ref: "ISOIL PA 95",
              visc: "",
            },
            td2: "Soaps, lubricants and anti-static",
            td3: "Anionic",
            td4: "95%	",
            td5: "Polyamide spinning",
          },
          {
            td1: {
              icon1: false,
              ref: "ISOIL PP",
              visc: "",
            },
            td2: "OE-OP copolymer and anti-static",
            td3: "Non-ionic",
            td4: "80%	",
            td5: "Polypropilene spinning",
          },
          {
            td1: {
              icon1: false,
              ref: "ISOIL 34",
              visc: "",
            },
            td2: "OE-OP copolymer, plus additives",
            td3: "Non-ionic",
            td4: "40%	",
            td5: "Mixed and synthetic fibre spinning.",
          },
          {
            td1: {
              icon1: false,
              ref: "ULTRATEX S-50 THERMICAN",
              visc: "",
            },
            td2: "Lubricant based on silicone oils and anti-static additive",
            td3: "Non-ionic",
            td4: "100%	",
            td5: "PP spinning. Water-repellent.",
          },
        ],
      },

      {
        title: "SPINNING LUBRICANTS - COLD SIZING AGENTS",
        tableHead: [
          "product",
          "Chemical composition",
          "Character",
          "Active M.",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ISOWACH",
              visc: "",
            },
            td2: "Polyglycols, esters and emulgents",
            td3: "Non-ionic",
            td4: "20%",
            td5: "Warping of all types of fibres.",
          },
          {
            td1: {
              icon1: false,
              ref: "ISOWACH 5",
              visc: "",
            },
            td2: "Glycol Polymers and emulgents",
            td3: "Non-ionic",
            td4: "20",
            td5: "	Warping of linen blends.",
          },
        ],
      },

      {
        title: "SPINNING AUXILIARES - ANTISTATIC AGENTS",
        tableHead: [
          "product",
          "Chemical composition",
          "Character",
          "Active M.",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ISOSTATIC PAT",
              visc: "",
            },
            td2: "Quaternary ammonium derivatives",
            td3: "Cationic",
            td4: "80%	",
            td5: "Open spinning and triturating materials.",
          },
          {
            td1: {
              icon1: false,
              ref: "ISOSTATIC PAT 2N",
              visc: "",
            },
            td2: "Quaternary ammonium derivatives",
            td3: "Cationic",
            td4: "40%	",
            td5: "Open spinning and triturating materials.",
          },
          {
            td1: {
              icon1: false,
              ref: "ISOSTATIC AN",
              visc: "",
            },
            td2: "Phosphoric esters",
            td3: "Anionic",
            td4: "20%	",
            td5: "Spinning of blends and synthetic fibres.",
          },
        ],
      },

      {
        title: "SPINNING AUXILIARIES - RESISTANCE GIVERS",
        tableHead: [
          "product",
          "Chemical composition",
          "Character",
          "Active M.",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ISOSIL AN 4 NF",
              visc: "",
            },
            td2: "Colloidal Silica Aqueous Dispersion",
            td3: "Anionic",
            td4: "40%	",
            td5: "Spinning and paper, fine particle.",
          },
          {
            td1: {
              icon1: false,
              ref: "ISOSIL AN 3 NF",
              visc: "",
            },
            td2: "Colloidal Silica Aqueous Dispersion",
            td3: "Anionic",
            td4: "30%	",
            td5: "Spinning and paper, fine particle.",
          },
          {
            td1: {
              icon1: false,
              ref: "ISOSIL 360 CA",
              visc: "",
            },
            td2: "Colloidal Silica Aqueous Dispersion",
            td3: "Anionic",
            td4: "30%",
            td5: "Spinning and paper with Food Grade requirements. Beverage clarification.",
          },
        ],
      },

      {
        title: "SPINNING LUBRICANTS - WETTING AGENTS",
        tableHead: [
          "product",
          "Chemical composition",
          "Character",
          "Active M.",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "HUMISOL 45",
              visc: "",
            },
            td2: "Sulphated surfactants",
            td3: "Anionic",
            td4: "45%",
            td5: "Spinning wetting agent.",
          },
        ],
      },

      {
        title: "SPINNING LUBRICANTS - EQUALIZERS",
        tableHead: [
          "product",
          "Chemical composition",
          "Character",
          "Active M.",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "ISOGAL AE",
              visc: "",
            },
            td2: "Aliphatic ester",
            td3: "Anionic",
            td4: "65 %",
            td5: "Dyeing equalizer, for dispersed and direct reagents and dyes.",
          },
        ],
      },

      {
        title: "OTHER LUBRICANTS",
        tableHead: [
          "product",
          "Chemical composition",
          "Character",
          "Active M.",
          "Description and applications",
        ],
        tableRows: [
          {
            td1: {
              icon1: true,
              ref: "ISOTEX T",
              visc: "",
            },
            td2: "White fluid non-toxic",
            td3: "Non-ionic",
            td4: "100 %",
            td5: "Polypropylene bag manufacturing. 3H NSF certified.",
          },
        ],
      },
    ],
  },

  {
    name: "sanitizers",
    id: "sanitizers",
    image: "../static/sanitizers.png",
    tables: [
      {
        title: "SANITIZERS FOR SURFACES AND HANDS AGAINST COVID-19",
        tableHead: ["product", "Description and applications"],
        tableRows: [
          {
            td1: {
              icon1: false,
              ref: "Gel de manos IPA",
              visc: "",
            },
            td2: "Effective skin-friendly hand sanitizing gel. Cosmetic quality product.",
          },
          {
            td1: {
              icon1: false,
              ref: "Ultradeg Higienizante",
              visc: "",
            },
            td2: "Effective sanitizer for all types of surfaces: metal, plastic, textiles, wood and glass. Suitable for use in hands. Cosmetic quality product.",
          },
        ],
      },
    ],
  },
];
