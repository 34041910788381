import styles from "./Details.module.css";
import { BiHome } from "react-icons/bi";
import { TfiLightBulb } from "react-icons/tfi";

const Details = () => {
  return (
    <div className={styles.container}>
      <div className={styles.leftSideWrapper}>
        <div className={styles.leftSideContent}>
          <div className={styles.detailTitle}>
            <h4>NEW FACILITIES EXPANDING PRODUCTION CAPACITY</h4>
            <h2>R&D</h2>
          </div>
          <div className={styles.content}>
            <p>
              At COGELSA we carry out studies related to technological advances
              and research: Research, development, and innovation.
            </p>
          </div>

          <div className={styles.detailsDiv}>
            <div className={styles.image}>
              <BiHome />
            </div>
            <div className={styles.content}>
              <h2>New facilities</h2>
              <p>
                It has production reactors. The company is continuously
                investing in new facilities and expanding its production
                capacity.
              </p>
            </div>
          </div>
          <div className={styles.detailsDiv}>
            <div className={styles.image}>
              <TfiLightBulb />
            </div>
            <div className={styles.content}>
              <h2>Research and development laboratory</h2>
              <p>
                Laboratory that has about 500 m² with 7 technicians. This is
                used for research, development, and quality assurance purposes.
                With the ability to design and formulate tailor-made lubricants
                to meet the new demands of the latest industrial applications.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.rightSide}>
        <img src="./static/containers.jpg" alt="containers" />
      </div>
    </div>
  );
};

export default Details;
