export const sectors = [
    {
        image: "./static/pharmacy.jpg",
        sector: "pharmacy and cosmetics",
    },
    {
        image: "./static/textile.jpg",
        sector: "textile",
    },
    {
        image: "./static/printing.jpg",
        sector: "printing",
    },
    {
        image: "./static/food.jpg",
        sector: "food and beverage",
    },
    {
        image: "./static/flat-glass.jpg",
        sector: "flat glass",
    },
    {
        image: "./static/automotive.jpg",
        sector: "automotive-metal",
    },
    {
        image: "./static/metal-package.jpg",
        sector: "metal packages",
    },
    {
        image: "./static/construction.jpg",
        sector: "construction",
    },
    {
        image: "./static/mining.jpg",
        sector: "mining",
    },
    {
        image: "./static/railways.jpg",
        sector: "railways",
    },
    {
        image: "./static/grease.jpg",
        sector: "special grease",
    },
    {
        image: "./static/lubricants.jpg",
        sector: "biodegrable lubricants",
    },
    {
        image: "./static/steel.jpg",
        sector: "steel",
    },
    {
        image: "./static/cement.jpg",
        sector: "cement",
    },
    {
        image: "./static/forging.jpg",
        sector: "cold forging",
    },
    {
        image: "./static/renewable-energies.jpg",
        sector: "renwable energies",
    },
];
