import React from "react";
import styles from "./R&D.module.css";

const RD = () => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h2>R&D</h2>
            </div>
            <div className={styles.content}>
                <p>
                    COGELSA has production reactors. The company is continuously investing
                    in new facilities and expanding its production capacity.
                </p>
                <p>
                    Laboratory that has about 500 m² with 7 technicians. This is used for
                    research, development and quality assurance purposes. With the ability
                    to design and formulate tailor-made lubricants to meet the new demands
                    of the latest industrial applications.
                </p>
                <p>
                    COGELSA has a complete range of services for the application of its
                    oils and grease:
                </p>
                <ul>
                    <li>
                        Friction tests, which provide information on friction temperature
                        and coefficient, to compare the lubricity of fluids.
                    </li>
                    <li>Compatibility studies with different components.</li>
                </ul>

                <p>
                    COGELSA can also provide other relevant data such as surface tension
                    and various key parameters of your fluids, for their perfect
                    monitoring.
                </p>
                <p>
                    We have focused on the development of special fluids for specific
                    applications taking into account the latest regulations and market
                    trends.
                </p>
            </div>
        </div>
    );
};

export default RD;
