import SectionOne from "../components/qualityAndSustainability/SectionOne";
import SectionTwo from "../components/qualityAndSustainability/SectionTwo";
import Sectionthree from "../components/qualityAndSustainability/Sectionthree";

const QualityAndSustainability = () => {
  return (
    <div>
      <SectionOne />
      <SectionTwo />
      <Sectionthree />
    </div>
  );
};

export default QualityAndSustainability;
