import React, { useState } from "react";
import styles from "./Searchbar.module.css";
import { MdSearch } from "react-icons/md";
import { products } from "../../store/products";
import { Link } from "react-router-dom";
import OutsideClicker from "../outsideClicker/OutsideClicker";

const Searchbar = ({ isSearchbarVisible, setSearchbarVisible }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = () => {
    const filteredResults = products.filter((product) =>
      product.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setSearchResults(filteredResults);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
    handleSearch();
  };

  const handleProductClick = () => {
    setSearchbarVisible(false);
  };

  const handleCloseSearchbar = () => {
    setSearchbarVisible(false);
    setSearchQuery("");
    setSearchResults([]);
  };

  return (
    <OutsideClicker handleClickOutside={handleCloseSearchbar}>
      {isSearchbarVisible && (
        <div className={styles.searchContainer}>
          <div className={styles.searchInput}>
            <input
              type="text"
              placeholder="Search for products..."
              autoFocus
              value={searchQuery}
              onChange={handleInputChange}
            />
            <Link to="/products" className={styles.icon}>
              <MdSearch />
            </Link>
          </div>

          {/* search result list */}
          {searchResults.length > 0 ? (
            <div className={styles.productsList}>
              <div className={styles.results}>
                {searchResults.map((result, index) => (
                  <Link
                    to={`/product/${result.id}`}
                    className={styles.product}
                    onClick={handleProductClick}
                    key={index}
                  >
                    <div className={styles.productName}>{result.name}</div>
                    <div className={styles.productImage}>
                      <img src={result.image} alt={result.name} />
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          ) : (
            <div className={styles.productsList}>
              <div className={styles.results}>
                {products.map((result, index) => (
                  <Link
                    to={`/product/${result.id}`}
                    className={styles.product}
                    onClick={handleProductClick}
                    key={index}
                  >
                    <div className={styles.productName}>{result.name}</div>
                    <div className={styles.productImage}>
                      <img src={result.image} alt={result.name} />
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </OutsideClicker>
  );
};

export default Searchbar;
