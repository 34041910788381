export const certificates = [
  {
    name: "certificate-1",
    url: "https://www.cogelsa.com/wp-content/uploads/2020/03/Certificado-ID_bajo_impacto-ambiental_2017.pdf",
    thumbnail: "../static/certificate-1.jpg",
  },
  {
    name: "certificate-2",
    url: "https://www.cogelsa.com/wp-content/uploads/2020/03/Certificado-ID_maquinaria-bajo-impacto_2017.pdf",
    thumbnail: "../static/certificate-2.jpg",
  },
  {
    name: "certificate-3",
    url: "https://www.cogelsa.com/wp-content/uploads/2021/07/ISO14001-COMPA%C3%91IA-GENERAL-DE-LUBRICANTES-SA-EN-21-24.pdf",
    thumbnail: "../static/certificate-3.jpg",
  },
  {
    name: "certificate-4",
    url: "https://www.cogelsa.com/wp-content/uploads/2021/07/ISO9001-COMPA%C3%91IA-GENERAL-DE-LUBRICANTES-SA-EN-21-24.pdf",
    thumbnail: "../static/certificate-4.jpg",
  },
  {
    name: "certificate-5",
    url: "https://www.cogelsa.com/wp-content/uploads/2023/02/ISO-21469-COMPA%C3%91IA-GENERAL-DE-LUBRICANTES-SA-1.pdf",
    thumbnail: "../static/certificate-5.jpg",
  },
  {
    // just an image
    name: "certificate-6",
    thumbnail: "../static/certificate-6.jpg",
  },
  {
    name: "certificate-7",
    url: "https://www.cogelsa.com/wp-content/uploads/2022/08/CH-COGELSA-IH-682-R04.01-200722.pdf",
    thumbnail: "../static/certificate-7.jpg",
  },
  {
    name: "certificate-8",
    thumbnail: "../static/certificate-8.png",
  },
  {
    name: "certificate-9",
    url: "https://www.cogelsa.com/wp-content/uploads/2022/04/Certificados-PP-2021-18.pdf",
    thumbnail: "../static/certificate-9.png",
  },
  {
    name: "certificate-10",
    url: "https://www.cogelsa.com/wp-content/uploads/2020/03/DOC171117.pdf",
    thumbnail: "../static/certificate-10.jpg",
  },
  {
    name: "certificate-11",
    url: "https://www.cogelsa.com/wp-content/uploads/2020/09/Ayuda-eficiencia-energetica.jpg",
    thumbnail: "../static/certificate-11.jpg",
  },
  {
    name: "certificate-12",
    url: "https://www.cogelsa.com/wp-content/uploads/2023/05/COMPANIA_GENERAL_DE_LUBRICANTES_SA_GROUP_GOLD_EcoVadis_Rating_Certificate_2023_04_11.pdf",
    thumbnail: "../static/certificate-12.png",
  },
];
