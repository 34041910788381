import { Link } from "react-router-dom";
import styles from "./Sectionthree.module.css";

const Sectionthree = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        QUALITY, FOOD SAFETY, ENVIRONMENT AND OCCUPATIONAL SAFETY POLICY
      </h2>

      <div className={styles.main}>
        <div className={styles.content}>
          <p>
            <span>
              <Link to={"/company"}>COGELSA</Link>
            </span>
            , as a packaging company of products that can end up with the final
            consumer, takes part in ECOEMBES, contributing to the Management
            System of packaging waste, aimed at achieving the recycling and
            recovery targets set by the Law on Packaging and Packaging Waste.
            This IMS offers a global, efficient solution in the management of
            household packaging waste from products placed on the market. The
            domestic packaging of the products marketed by COGELSA, therefore
            collaborate in the financing of the costs for the management of
            packaging waste.
          </p>
          <p>
            COGELSA, is adhered to the INTEGRATED MANAGEMENT SYSTEM FOR
            INDEPENDENT PRODUCERS (SIGPI in Spanish), in order to fulfil the
            obligations of R.D: 679/2006 of 2 June, regulating the management of
            used industrial oils.
          </p>
        </div>
        <div className={styles.image}>
          <img src="../static/tree-leaves.jpg" alt="tree leaves" />
        </div>
      </div>
    </div>
  );
};

export default Sectionthree;
