import React from "react";
import CataloguesCard from "../components/cataloguesCard/CataloguesCard";
import CtaloguesHeader from "../components/cataloguesHeader/CtaloguesHeader";

const Catalogues = () => {
    return (
        <div>
            <CtaloguesHeader />
            <CataloguesCard />
        </div>
    );
};

export default Catalogues;
