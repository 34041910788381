export const catalogues = [
    {
        name: "catalogue1",
        image: "./static/food-catalogue.jpg",
        description: "lubricants for the food industry",
        link: "https://www.cogelsa.com/wp-content/uploads/2020/11/COGELSA-food-industry-catalogue-LD.pdf",
    },
    {
        name: "catalogue2",
        image: "./static/lubricants-catalogue.jpg",
        description: "industrial lubricants general catalogue",
        button: "工业润滑油产品总目录 ",
        link: "https://www.cogelsa.com/wp-content/uploads/2020/04/COGELSA-catalogue-EN.pdf",
        link2: "https://www.cogelsa.com/wp-content/uploads/2021/04/工业润滑油产品总目录COGELSA-LD.pdf",
    },
    {
        name: "catalogue3",
        image: "./static/textile-catalogue.png",
        description: "lubricants for textile industry",
        link: "https://www.cogelsa.com/wp-content/uploads/2021/05/Catalogo-TEXTIL-COGELSA-LD-1.pdf",
    },
    {
        name: "catalogue4",
        image: "./static/sawmills-catalogue.jpg",
        description: "lubricants for sawmills",
        link: "https://www.cogelsa.com/wp-content/uploads/2022/01/Catálogo-Aserraderos-COGELSA-LD.pdf",
    },
    {
        name: "catalogue5",
        image: "./static/degreasers-catalogue.jpg",
        description: "degreasers and cleaning fluids",
        link: "https://www.cogelsa.com/wp-content/uploads/2023/04/Degreasers-cleaning-fluids-Dégraissants-fluides-nettoyage-Desengrasantes-fluidos-limpieza-COGELSA-LD.pdf",
    },
];
