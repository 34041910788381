import React from "react";
import styles from "./Slider.module.css";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";

import "./Slider.module.css";

// import required modules
import { Autoplay, Pagination } from "swiper";

const Slider = () => {
  return (
    <div>
      <Swiper
        style={{ height: "60vh" }}
        pagination={{
          dynamicBullets: true,
          clickable: true,
        }}
        modules={[Pagination, Autoplay]}
        loop={true}
        autoplay={{ delay: 10000 }}
        className={styles.mySwiper}
      >
        <SwiperSlide>
          <div className={styles.slideContainer}>
            <img
              src="./static/slide1.png"
              alt="slide1"
              // style={{ objectFit: "cover", width: "100%" }}
            />
            <div class={styles.contentOverlay}>
              <div class={styles.content}>
                <h2>COGELSA</h2>
                <p>
                  More than 100 years providing efficient solutions for
                  industrial lubrication
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.slideContainer}>
            <img src="./static/slide2.jpg" alt="slide2" />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Slider;
