import Topbar from "./Topbar";
import Navbar from "./Navbar";
import Footer from "./Footer";
import ScrollButton from "./ScrollButton";

const Layout = ({ children }) => {
    return (
        <div
        // style={{ boxSizing: "border-box", width: "100vw", overflowX: "hidden" }}
        >
            <Topbar />
            <Navbar />
            <ScrollButton />
            <main
            // style={{ boxSizing: "border-box", width: "100vw", overflowX: "hidden" }}
            >
                {children}
            </main>
            <Footer />
        </div>
    );
};

export default Layout;
