import React from "react";
import styles from "./CompanyMainSection.module.css";
import ReactPlayer from "react-player";

const CompanyMainSection = () => {
  return (
    <div className={styles.container}>
      <div className={styles.videoContainer}>
        <ReactPlayer
          className={styles.video}
          url="https://vimeo.com/730042789?embedded=true&source=vimeo_logo&owner=180445560"
          playing={true}
          loop={true}
          controls={true}
        />

        <div className={styles.description}>
          <p>
            COGELSA is a company that develops, manufactures and markets
            high-tech lubricants and grease, under the most demanding European
            standards, with the utmost respect for the environment and social
            responsibility.
          </p>
          <p>
            Established more than 100 years ago in the area of Barcelona, Spain,
            the company has accumulated vast experience in different fields of
            industrial applications.
          </p>
          <p>
            It works closely with various additive suppliers and research
            institutes to learn about the latest technologies, allowing the R&D
            teams to formulate state-of-the-art speciality lubricants.
          </p>
          <p>
            COGELSA is present in more than 30 countries around the world
            through its wide distribution network, from Asia to Europe, North
            Africa and Latin America.
          </p>
          <p>
            COGELSA offers a high level technical service to advise on the
            product that should be used, as well as to follow up the performance
            of the product in situ with periodic laboratory analyses that allow
            a long life cycle.
          </p>
          <p>
            We supply lubricants and grease to major multi-national companies
            that have the highest requirements with regards to the stability and
            performance of products.
          </p>
          <p>
            We shall be happy to be your technical partner to allow you to work
            without interruptions and optimize your production processes.
          </p>
          <p>
            We are also the exclusive distributors for Spain and Portugal of the
            high-tech, specialist application lubricants BECHEM, ANDEROL,
            MEMOLUB and ELKALUB.
          </p>
          <p>Allow COGELSA to take care of your industrial fluids!</p>
        </div>
      </div>
      <div className={styles.imagesContainer}>
        <img
          src="../static/company1.jpg"
          alt="company"
          className={styles.image1}
        />
        <img
          src="../static/company2.jpg"
          alt="company"
          className={styles.image2}
        />
      </div>
    </div>
  );
};

export default CompanyMainSection;
