import React from "react";
import { catalogues } from "../../store/catalogues";
import { GrShare } from "react-icons/gr";
import styles from "./CataloguesCard.module.css";

const CataloguesCard = () => {
    return (
        <div className={styles.container}>
            {catalogues.map((catalogue) => (
                <div key={catalogue.name} className={styles.cardContainer}>
                    <div className={styles.card}>
                        <img src={catalogue.image} alt="catalogue" />
                        <div className={styles.description}>
                            <p>{catalogue.description}</p>
                        </div>
                    </div>

                    <a href={catalogue.link} target="_blank" rel="noreferrer">
                        english catalogue <GrShare className={styles.icon} />
                    </a>

                    {catalogue.button && (
                        <a href={catalogue.link2} target="_blank" rel="noreferrer">
                            {catalogue.button} <GrShare className={styles.icon} />
                        </a>
                    )}
                </div>
            ))}
        </div>
    );
};

export default CataloguesCard;
