import { useEffect, useRef } from "react";

const OutsideClicker = ({ children, handleClickOutside }) => {
  const componentRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        handleClickOutside();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleClickOutside]);

  return <div ref={componentRef}>{children}</div>;
};

export default OutsideClicker;
