import styles from "./Distributors.module.css";

const Distributors = () => {
  return (
    <div className={styles.container}>
      <div className={styles.leftSide}>
        <div className={styles.header}>
          <p>Official distributor for Spain of</p>
        </div>
        <div className={styles.imagesContainer}>
          <div className={styles.image} title="Elkalub">
            <img
              className={styles.elkalub}
              src="./static/elkalub.jpg"
              alt="elkalub"
            />
          </div>
          <div className={styles.image} title="Bechem Lubrication">
            <img
              className={styles.bechem}
              src="./static/bechem.jpg"
              alt="bechem"
            />
          </div>
          <div className={styles.image} title="Memolub">
            <img
              className={styles.memolub}
              src="./static/memolub.jpg"
              alt="memolub"
            />
          </div>
          <div className={styles.image} title="Anderol">
            <img
              className={styles.anderol}
              src="./static/anderol.png"
              alt="anderol"
            />
          </div>
        </div>
      </div>
      <div className={styles.rightSide}>
        <div className={styles.header}>
          <p>Official distributor for Portugal of</p>
        </div>
        <div className={styles.imagesContainer}>
          <div className={styles.image} title="Bechem Lubrication">
            <img
              className={styles.bechem}
              src="./static/bechem.jpg"
              alt="bechem"
            />
          </div>
          <div className={styles.image} title="Memolub">
            <img
              className={styles.memolub}
              src="./static/memolub.jpg"
              alt="memolub"
            />
          </div>
          <div className={styles.image} title="Anderol">
            <img
              className={styles.anderol}
              src="./static/anderol.png"
              alt="anderol"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Distributors;
