import React from "react";
import styles from "./CompanyHeader.module.css";

const CompanyHeader = () => {
    return (
        <div className={styles.header}>
            <h2>COGELSA</h2>
            <p>
                MORE THAN 100 YEARS PROVIDING EFFICIENT SOLUTIONS FOR INDUSTRIAL
                LUBRICATION
            </p>
        </div>
    );
};

export default CompanyHeader;
