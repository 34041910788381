import styles from "./Introduction.module.css";

const Introduction = () => {
  return (
    <div className={styles.introductionContainer}>
      <h2>Introduction</h2>
      <p>
        <span>Mehdi Trading International (MTI) </span>
        is a prominent company established in August 2002. Our core focus at MTI
        is the import, export, and marketing of a wide range of products and raw
        materials dedicated to the textile industry. We specialize in offering
        equipment, spare parts, sewing machine needles, knitting machine
        needles, fabric machine needles, fabric threads, sewing threads, and
        various accessories. Additionally, we provide exceptional technical
        assistance and repair services for all types of equipment.
      </p>
      <p>
        We proudly represent COGELSA, an esteemed company renowned for its
        development, manufacturing, and marketing of cutting-edge lubricants and
        greases. Our products adhere to the highest European standards, ensuring
        optimal performance while prioritizing environmental sustainability and
        social responsibility.
      </p>
      <p>
        With over a century of experience, COGELSA has solidified its presence
        in Barcelona, Spain, and accumulated extensive expertise across diverse
        industrial applications.
      </p>
      <p>
        We are committed to delivering excellence in the textile industry and
        beyond, consistently exceeding our clients' expectations through
        innovative solutions and exceptional customer service.
      </p>
    </div>
  );
};

export default Introduction;
