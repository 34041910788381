import React from "react";
import { sectors } from "../../store/sectors";
import styles from "./SectorsCard.module.css";

const SectorsCard = () => {
    return (
        <div className={styles.container}>
            {sectors.map((sector) => (
                <div key={sector.sector} className={styles.card}>
                    <img src={sector.image} alt={sector.sector} />
                    <div className={styles.description}>
                        <p>{sector.sector}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default SectorsCard;
