import styles from "./Navbar.module.css";
import { Link, NavLink } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { MdOutlineSearch, MdClose } from "react-icons/md";

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Searchbar from "../searchbar/Searchbar";

const Navbar = () => {
  const [isSearchbarVisible, setSearchbarVisible] = useState(false);
  const location = useLocation();

  const handleToggle = () => {
    setSearchbarVisible((prevState) => !prevState);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);

  return (
    <>
      <div className={styles.NavbarContainer}>
        <Link to="/" className={styles.logo}>
          <img src="../static/logo.png" alt="company logo" />
        </Link>
        <ul className={styles.menuList}>
          <li>
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? styles.active : "")}
            >
              HOME
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/company"
              className={({ isActive }) => (isActive ? styles.active : "")}
            >
              COMPANY
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/quality-and-sustainability"
              className={({ isActive }) => (isActive ? styles.active : "")}
            >
              QUALITY AND SUSTAINABILITY
            </NavLink>
          </li>
          <li className={styles.products}>
            <NavLink
              to="/products"
              className={({ isActive }) =>
                isActive ? styles.active : styles.productsItem
              }
            >
              PRODUCTS <IoIosArrowDown />
            </NavLink>
            <div className={styles.productsListContainer}>
              <ul className={styles.productsList}>
                <li className={styles.item1}>
                  <NavLink to={`/product/industrial-oils`}>
                    INDUSTRIAL OILS
                  </NavLink>
                </li>
                <li className={styles.item2}>
                  <NavLink to={`/product/neat-cutting-oils`}>
                    NEAT CUTTING OILS
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/product/degreasers`}>DEGREASERS</NavLink>
                </li>
                <li>
                  <NavLink to={`/product/textile-industry`}>
                    PRODUCTS FOR TEXTILE INDUSTRY
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/product/lubricant-greases`}>
                    LUBRICANTS GREASES
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/product/metal-forming-lubricants`}>
                    METAL FORMING LUBRICANTS
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/product/sprays`}>SPRAYS</NavLink>
                </li>
                <li>
                  <NavLink to={`/product/sanitizers`}>SANITIZERS</NavLink>
                </li>
                <li>
                  <NavLink to={`/product/water-miscible-coolants`}>
                    WATER MISCIBLE COOLANTS
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/product/metal-protection`}>
                    METAL PROTECTION
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/product/mould-releasers`}>
                    MOULD RELEASERS FOR CONSTRUCTION AND CIVIL WORKS
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <NavLink
              to="catalogues"
              className={({ isActive }) => (isActive ? styles.active : "")}
            >
              CATALOGUES
            </NavLink>
          </li>
          <li>
            <NavLink
              to="contact"
              className={({ isActive }) => (isActive ? styles.active : "")}
            >
              CONTACT&nbsp;US
            </NavLink>
          </li>
        </ul>
        <div className={styles.iconContainer}>
          {isSearchbarVisible ? (
            <MdClose className={styles.closeIcon} onClick={handleToggle} />
          ) : (
            <MdOutlineSearch
              className={styles.searchIcon}
              onClick={handleToggle}
            />
          )}
          <Searchbar
            isSearchbarVisible={isSearchbarVisible}
            setSearchbarVisible={setSearchbarVisible}
          />
        </div>
      </div>
    </>
  );
};

export default Navbar;
