import React from "react";
import { BsTelephone } from "react-icons/bs";
import { MdOutlineEmail } from "react-icons/md";
import { FaLinkedinIn } from "react-icons/fa";
import { ReactComponent as SpainIcon } from "../../assets/spain.svg";
import { ReactComponent as UkIcon } from "../../assets/uk.svg";
import { ReactComponent as FranceIcon } from "../../assets/france.svg";
import styles from "./Topbar.module.css";
import { Link } from "react-router-dom";

const Topbar = () => {
  return (
    <div className={styles.topbar_container}>
      <div className={styles.left_side}>
        <div className={styles.phone_contact}>
          <Link
            to="https://api.whatsapp.com/send?phone=21620587586"
            target="_blank"
            rel="noreferrer"
          >
            <BsTelephone className={styles.phoneIcon} />
            <span>+216 20 587 586</span>
          </Link>
        </div>
        <div className={styles.email_contact}>
          <Link to="mailto:n.souibgui@gmail.com">
            <MdOutlineEmail className={styles.emailIcon} />
            <span>n.souibgui@gmail.com</span>
          </Link>
        </div>
        <div className={styles.linkedin}>
          <Link
            to="https://www.linkedin.com/in/souibgui-med-mahdi-8818bb209/?fbclid=IwAR1FHwXHcOAZ41wpMckHAyUX_ABhYPMJRZkcnn9uXLsXEKWkoxNafxc7Kgw"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedinIn className={styles.linkedinIcon} />
          </Link>
        </div>
      </div>

      {/* visibility hidden */}
      <div className={styles.right_side}>
        <div>
          <SpainIcon />
        </div>
        <div>
          <UkIcon />
        </div>
        <div>
          <FranceIcon />
        </div>
      </div>
    </div>
  );
};

export default Topbar;
