import React from "react";
import Slider from "../components/slider/Slider";
import Header from "../components/header/Header";
import AboutCompany from "../components/aboutCompany/AboutCompany";
import Details from "../components/details/Details";
import ProductsCatalogue from "../components/productsCatalogue/ProductsCatalogue";
import Distributors from "../components/distributors/Distributors";
import Introduction from "../components/introduction/Introduction";

const Home = () => {
  return (
    <div>
      <Slider />
      <Introduction />
      <Header />
      <AboutCompany />
      <Details />
      <ProductsCatalogue />
      <Distributors />
    </div>
  );
};

export default Home;
