import React from "react";
import CompanyHeader from "../components/companyHeader/CompanyHeader";
import CompanyMainSection from "../components/companyMainSection/CompanyMainSection";
import RD from "../components/R&D/R&D";
import MarketSectors from "../components/marketSectors/MarketSectors";
import Collaborators from "../components/collaborators/Collaborators";

const Company = () => {
  return (
    <div>
      <CompanyHeader />
      <CompanyMainSection />
      <RD />
      <MarketSectors />
      <Collaborators />
    </div>
  );
};

export default Company;
