import React, { useState, useEffect } from "react";
import { IoIosArrowUp } from "react-icons/io";
import styles from "./ScrollButton.module.css";

const ScrollButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const handleScroll = () => {
        const scrollY = window.scrollY;
        if (scrollY > 200) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <div
            className={`${styles.scrollToTopArrow} ${isVisible ? styles.show : ""}`}
            onClick={scrollToTop}
        >
            <IoIosArrowUp />
        </div>
    );
};

export default ScrollButton;
