import React from "react";
import styles from "./ContactInfo.module.css";
import { Link } from "react-router-dom";
import { BsLinkedin } from "react-icons/bs";
import { MdOutlinePhoneIphone } from "react-icons/md";
import { FaMailBulk } from "react-icons/fa";
import { GiRotaryPhone } from "react-icons/gi";
import emailjs from "emailjs-com";

const ContactInfo = () => {
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_187kc6n",
      "template_97iv8gy",
      e.target,
      "FysuYEyGAhzulnmrv"
    );
    e.target.reset();
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftSide}>
        <div className={styles.content}>
          <p>
            Please, contact us by filling out this form. You can also do this by
            calling{" "}
            <Link to="tel:+21679320068" target="_blank" rel="noreferrer">
              +216 79 320 068{" "}
            </Link>{" "}
            {"/"}
            <Link
              to="https://api.whatsapp.com/send?phone=21620587586"
              target="_blank"
              rel="noreferrer"
            >
              +216 20 587 586
            </Link>{" "}
            or by sending an email to{" "}
            <Link
              to="mailto:n.souibgui@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              n.souibgui@gmail.com
            </Link>
          </p>
        </div>
        <div className={styles.form}>
          <form onSubmit={sendEmail}>
            <input type="name" name="name" placeholder="Name*" required />
            <input type="email" name="email" placeholder="Email*" required />
            <input type="tel" name="phone" placeholder="Phone*" />
            <input type="text" name="company" placeholder="Company*" />
            <input type="text" name="provine" placeholder="Province" />
            <input type="text" name="country" placeholder="Country*" />
            <textarea name="message" required></textarea>
            <button type="submit">Send</button>
          </form>
        </div>
      </div>
      <div className={styles.rightSide}>
        <Link
          to="https://www.linkedin.com/in/souibgui-med-mahdi-8818bb209/?fbclid=IwAR1FHwXHcOAZ41wpMckHAyUX_ABhYPMJRZkcnn9uXLsXEKWkoxNafxc7Kgw"
          target="_blank"
        >
          <BsLinkedin />
        </Link>
        <Link to="tel:+21679320068" target="_blank">
          <GiRotaryPhone /> +216 79 320 068
        </Link>
        <Link
          to="https://api.whatsapp.com/send?phone=21620587586"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          <MdOutlinePhoneIphone /> +216 20 587 586
        </Link>
        <Link to="mailto:n.souibgui@gmail.com" target="_blank">
          <FaMailBulk /> n.souibgui@gmail.com
        </Link>
        {/* Google Maps */}
        <div>
          <div className={styles.mapFrame}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d199.9199016487976!2d10.159920771076436!3d36.705303600136766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd30b2a0f9aeb3%3A0x5063b71ddc6d7de!2sP545%2B4X6%2C%20Fouchana!5e0!3m2!1sfr!2stn!4v1684772670020!5m2!1sfr!2stn"
              title="Google Maps"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
