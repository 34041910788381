import styles from "./Product.module.css";
import { products } from "../../store/products";
import { useParams } from "react-router-dom";

const Product = () => {
  const { id } = useParams();

  const product = products.find((product) => product.id === id);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container} key={product.name}>
        <div className={styles.header}>
          <div className={styles.image}>
            <img src={product.image} alt={product.name} />
          </div>
          <h2>{product.name}</h2>
        </div>

        {product.tables.map((table, index) => (
          <div className={styles.tableWrapper} key={index}>
            <h2>{table.title}</h2>
            <div className={styles.tableContainer}>
              <table className={styles.table}>
                <thead className={styles.tableHead}>
                  <tr>
                    {table.tableHead.map((head, idx) => (
                      <th key={idx}>{head}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {table.tableRows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {/* First table data */}
                      <td>
                        <div className={styles.td1}>
                          {row.td1.icon1 && (
                            <img src="../static/NSF.png" alt="NSF" />
                          )}
                          {row.td1.icon2 && (
                            <img src="../static/halal.jpg" alt="NSF" />
                          )}
                          <div>{row.td1.ref}</div>

                          <div>
                            {row.td1.visc && (
                              <>
                                {Array.isArray(row.td1.visc) &&
                                  row.td1.visc.map((value, index) => (
                                    <div
                                      key={index}
                                      style={{ minWidth: "45px" }}
                                    >
                                      {value}
                                    </div>
                                  ))}
                                {!Array.isArray(row.td1.visc) && (
                                  <div style={{ minWidth: "45px" }}>
                                    {row.td1.visc}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </td>

                      {/* Second table data */}
                      {row.td2 && (
                        <td className={styles.td2}>
                          <div>
                            {Array.isArray(row.td2) ? (
                              row.td2.map((value, index) => (
                                <div key={index}>{value}</div>
                              ))
                            ) : (
                              <div>{row.td2}</div>
                            )}
                          </div>
                        </td>
                      )}

                      {/* Third table data */}
                      {row.td3 && (
                        <td className={styles.td3}>
                          {Array.isArray(row.td3) ? (
                            row.td3.map((value, index) => (
                              <div key={index}>{value}</div>
                            ))
                          ) : (
                            <div>{row.td3}</div>
                          )}
                        </td>
                      )}

                      {/* Fourth table data */}
                      {row.td4 && (
                        <td className={styles.td4}>
                          {Array.isArray(row.td4) ? (
                            row.td4.map((value, index) => (
                              <div key={index}>{value}</div>
                            ))
                          ) : (
                            <div>{row.td4}</div>
                          )}
                        </td>
                      )}

                      {/* Fifth table data */}
                      {row.td5 && (
                        <td className={styles.td5}>
                          {Array.isArray(row.td5) ? (
                            row.td5.map((value, index) => (
                              <div key={index}>{value}</div>
                            ))
                          ) : (
                            <div>{row.td5}</div>
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Product;
