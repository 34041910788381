import React from "react";
import styles from "./MarketSectors.module.css";
import SectorsCard from "../sectorsCards/SectorsCard";

const MarketSectors = () => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h2>target market sectors</h2>
            </div>
            <div>
                <SectorsCard />
            </div>
        </div>
    );
};

export default MarketSectors;
