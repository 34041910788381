export const accordionContent = [
  {
    header: "Meeting the needs of our customers",
    paragraph:
      "To satisfy the needs of our customers, to achieve our own personal satisfaction, and to consolidate and improve our competitiveness and our expansion in the market:",
    list: [
      "To prioritise the prevention of defects rather than their correction.",
      "To strengthen production planning in order to maximise the performance of the facilities.",
      "To provide the necessary resources for the improvement of process control and the achievement of objectives and targets.",
      "To ensure the maintenance of the company’s facilities, as well as the order and cleanliness of the factory.",
      "Comply with food safety requirements for our food contact products, including legal and regulatory requirements and those of our customers to maintain food safety.",
    ],
  },
  {
    header:
      "To carry out our activity with the utmost respect for the protection of the environment:",
    paragraph:
      "To carry out our activity with the utmost respect for the protection of the environment:",
    list: [
      "Identify and ensure the correct management of environmental aspects.",
      "Efficient and sustainable management of natural resources, energy efficiency, the use of renewable energies and the reduction of greenhouse gases.",
      "Minimise waste.",
      "Prevent pollution and, where possible, minimise environmental impact.",
      "Incorporate environmental criteria in the management of the business.",
    ],
  },
  {
    header: "Ensuring safety protection at work:",
    paragraph: "Ensuring safety protection at work:",
    list: [
      "Identify and prevent occupational hazards.",
      "To have the appropriate installations, equipment and tools.",
      "Favouring coordination with personnel for risk management.",
      "Incident and accident management.",
      "Emergency preparedness and fire safety.",
      "Incorporate safety criteria in the management of the business.",
    ],
  },
  {
    header: "To guarantee our activity in food safety",
    paragraph: "To guarantee our activity in food safety:",
    list: [
      "Establish internal and external communication to guarantee the safety of food products.",
      "Establish continuous improvement to maintain all the food safety guarantees of our process and products.",
      "To work on the continuous training of staff in hygiene and handling of food products.",
    ],
  },
];
