import { useState } from "react";
import styles from "./SectionTwo.module.css";
import {
  BsFillArrowRightCircleFill,
  BsDot,
  BsFillPlusCircleFill,
  BsFillDashCircleFill,
} from "react-icons/bs";
import { accordionContent } from "../../store/accordionContent";

const SectionTwo = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleAccordionClick = (index) => {
    if (index === expandedIndex) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        QUALITY, FOOD SAFETY, ENVIRONMENT AND OCCUPATIONAL SAFETY POLICY
      </h2>

      <div className={styles.main}>
        <div className={styles.content}>
          <p>
            COGELSA, a company dedicated to the DESIGN, DEVELOPMENT,
            MANUFACTURING, MARKETING AND TECHNICAL ADVICE OF INDUSTRIAL
            LUBRICANT PRODUCTS, understands that it is key to the development of
            the organisation and, therefore, establishes the following
            fundamental pillars as its reference policy:
          </p>
          <ul>
            <li>
              <span>
                <BsDot />
              </span>
              <p>
                Continuous improvement of the Quality of products and services,
                of the Environmental Management System and of the Safety System.
              </p>
            </li>
            <li>
              <span>
                <BsDot />
              </span>
              <p>Compliance with current legislation.</p>
            </li>
            <li>
              <span>
                <BsDot />
              </span>
              <p>
                Training and information of employees and the agents involved as
                an essential element for the development of the organisation,
                achieving their full integration and participation.
              </p>
            </li>
          </ul>
          <p>
            Our commitment to sustainability, as set out in COGELSA’s company
            policy, has led us to be audited by EcoVadis with the aim of
            contributing to continuous improvement and sustainable development.
            The score achieved has earned us the EcoVadis gold medal.
          </p>
        </div>
        {accordionContent.map((content, idx) => (
          <div key={idx} className={styles.accordion}>
            <div
              className={styles.head}
              onClick={() => handleAccordionClick(idx)}
            >
              <h3>{content.header}</h3>{" "}
              <span>
                {expandedIndex === idx ? (
                  <BsFillDashCircleFill />
                ) : (
                  <BsFillPlusCircleFill />
                )}
              </span>
            </div>
            <div
              className={`${styles.body} ${styles.longText} ${
                expandedIndex === idx && styles.expanded
              } `}
            >
              <h5>{content.paragraph}</h5>

              <ul className={styles.list}>
                {content.list.map((item, index) => (
                  <li key={index}>
                    <span>
                      <BsFillArrowRightCircleFill />
                    </span>
                    <p>{item}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionTwo;
