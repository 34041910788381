import styles from "./SectionOne.module.css";

import { certificates } from "../../store/certificates";
import { useEffect, useState } from "react";

const SectionOne = () => {
  const [collapse, setCollapse] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleCollapse = () => {
    setCollapse((setPrev) => !setPrev);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.firstSectionContainer}>
        <h2 className={styles.title}>CERTIFICATIONS</h2>
        <div className={styles.firstSection}>
          <div className={styles.content}>
            <p>
              At COGELSA we have the necessary technical team and human
              resources to design and manufacture products with the uniqueness
              that each project requires, with the concepts of quality,
              environment, innovation and continuous improvement that are
              managed under the Six Sigma philosophy, being at the forefront,
              with a clear focus on the customer. Therefore, our work system is
              in accordance with International Quality Standards UNE-EN-ISO 9001
              and Environment UNE-EN-ISO 14001, in which we have been certified
              since 1999 and 2003, respectively.
            </p>
            <span
              className={`${styles.longText} ${
                windowWidth > 768
                  ? styles.expanded
                  : collapse
                  ? styles.expanded
                  : ""
              }`}
            >
              <p>
                We are also certified as research and development for the
                following projects: “Metales 015, research and development of
                lubricants for metal processing with low environmental impact”
                and “Research and development of special lubricants for
                low-impact machinery”. Projects certified by EQA with numbers
                461.836 and 461.871 in the field of application and
                interpretation of tax benefits (tax deduction for R&D&I
                activities). This certification does not imply approval or
                homologation of the product, process or service that is the
                object of the project.
              </p>
              <p>
                <strong>
                  COGELSA, CERTIFIED WITH THE ISO 21469:2006 STANDARD
                </strong>
              </p>
              <p>
                COGELSA complies with the ISO 21469:2006 standard, which
                specifies the hygiene requirements for the formulation,
                manufacture, use and conservation of lubricants that may be in
                occasional contact with products and packaging used in the food,
                cosmetics, pharmaceutical and animal feed industries. This
                certification is the highest recognition that a manufacturer of
                food grade lubricants can achieve, certifying that its products
                are produced in a clean environment, with the best manufacturing
                practices and the use of safe components.
              </p>
              <p>
                The Halal Institute has granted COGELSA Halal certification for
                the products that make up the wide range of lubricants suitable
                for accidental contact with food (category H1) and vaselines.
              </p>
              <p>
                COGELSA has a wide range of products approved by the
                certification body NSF International.
              </p>
            </span>

            {windowWidth <= 768 && (
              <button onClick={handleCollapse}>
                {collapse ? "Show less" : "Show more"}
              </button>
            )}
          </div>

          <div className={styles.image}>
            <img src="../static/certificationPage-image.jpg" alt="laboratory" />
          </div>

          {/* Certificates */}
          <div className={styles.certificates}>
            <div className={styles.certificatesContainer}>
              {certificates.map((certificate) => (
                <div key={certificate.name} className={styles.certificatesBox}>
                  {certificate.url ? (
                    <div className={styles.certificateCard}>
                      <a
                        href={certificate.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={certificate.thumbnail} alt="" />
                      </a>
                    </div>
                  ) : (
                    <div className={styles.certificateCard}>
                      <img src={certificate.thumbnail} alt={certificate.name} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
