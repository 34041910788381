import styles from "./ProductsCatalogue.module.css";

import { products } from "../../store/products";
import { Link } from "react-router-dom";

const ProductsCatalogue = () => {
  return (
    <div>
      <h2 className={styles.title}>Our Products</h2>
      <div className={styles.productsContainer}>
        {products.map((product) => (
          <div className={styles.productsCard} key={product.id}>
            <img src={product.image} alt={product.name} />
            <div className={styles.description}>
              <h2 className={styles.productName}>{product.name}</h2>
              <Link to={`/product/${product.id}`} className={styles.btn}>
                More Details
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductsCatalogue;
