import { Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import Layout from "./components/layout/Layout.jsx";
import Company from "./pages/Company";
import Catalogues from "./pages/Catalogues";
import Contact from "./pages/Contact";
import Product from "./components/product/Product";
import ProductsCatalogue from "./components/productsCatalogue/ProductsCatalogue";
import QualityAndSustainability from "./pages/QualityAndSustainability";

const App = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/quality-and-sustainability"
          element={<QualityAndSustainability />}
        />
        <Route path="/company" element={<Company />} />
        <Route path="/products" element={<ProductsCatalogue />} />
        <Route path="/product/:id" element={<Product />} />
        <Route path="/catalogues" element={<Catalogues />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Layout>
  );
};

export default App;
