import React from "react";
import ContactInfo from "../components/contactInfo/ContactInfo";

const Contact = () => {
    return (
        <div>
            <ContactInfo />
        </div>
    );
};

export default Contact;
