import React from "react";
import styles from "./Collaborators.module.css";

const Collaborators = () => {
    return (
        <div className={styles.container}>
            <div className={styles.firstRow}>
                <img src="./static/veritas1.jpg" alt="veritas" />
                <img src="./static/veritas2.jpg" alt="veritas" />
                <img src="./static/halal.jpg" alt="halal" />
                <img src="./static/NSF2.jpg" alt="NSF" />
            </div>
            <div className={styles.secondRowContainer}>
                <p>member of</p>
                <div className={styles.secondRow}>
                    <img src="./static/SME.png" alt="SME" />
                    <img src="./static/kiwa.jpg" alt="kiwa" />
                    <img src="./static/aselube.jpg" alt="aselube" />
                </div>
            </div>
        </div>
    );
};

export default Collaborators;
