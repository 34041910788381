import { Link } from "react-router-dom";
import styles from "./AboutCompany.module.css";

import { TfiSettings } from "react-icons/tfi";
import { MdOutlineNavigateNext } from "react-icons/md";

const AboutCompany = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.image}>
          <TfiSettings />
        </div>

        <h2 className={styles.title}>
          More than 100 years in the market and developing technology
        </h2>

        <p className={styles.content}>
          From COGELSA, we supply lubricants and grease to the main
          multi-national companies that have the highest requirements with
          regards to the stability and performance of products.
        </p>
        <div className={styles.link}>
          <Link to={"/company"}>MORE INFORMATION</Link>
          <span>
            <MdOutlineNavigateNext />
          </span>
        </div>
      </div>
    </div>
  );
};

export default AboutCompany;
