import React from "react";
import styles from "./CataloguesHeader.module.css";

const CtaloguesHeader = () => {
    return (
        <div>
            <div className={styles.header}>
                <h4>catalogues</h4>
            </div>
        </div>
    );
};

export default CtaloguesHeader;
