import React from "react";
import styles from "./Header.module.css";
import { Link } from "react-router-dom";
import { MdOutlineNavigateNext } from "react-icons/md";

const Header = () => {
  return (
    <div className={styles.container}>
      <div className={styles.leftSide}>
        <img src="./static/barrels.jpg" alt="barrels" />
      </div>
      <div className={styles.rightSide}>
        <div className={styles.headerTitle}>
          <h4>
            COGELSA, MORE THAN 100 YEARS PROVIDING EFFICIENT SOLUTIONS FOR
            INDUSTRIAL LUBRICATION
          </h4>
          <h2>COGELSA Efficient Lubrication</h2>
        </div>
        <div className={styles.content}>
          <p>
            COGELSA is a company that develops, manufactures and markets
            high-tech lubricants and grease, under the most demanding European
            standards, with the utmost respect for the environment and social
            responsibility.
          </p>
          <p>
            Established more than 100 years ago in the area of Barcelona, Spain,
            the company has accumulated vast experience in different fields of
            industrial applications.
          </p>
          <p>
            It works closely with various additive suppliers and research
            institutes to learn about the latest technologies, allowing the R&D
            teams to formulate state-of-the-art speciality lubricants.
          </p>
          <p>
            COGELSA is present in more than 30 countries around the world
            through its wide distribution network, from Asia to Europe, North
            Africa and Latin America.
          </p>
        </div>
        <div className={styles.link}>
          <Link to={"/company"}>COGELSA</Link>
          <span>
            <MdOutlineNavigateNext />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Header;
